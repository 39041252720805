import * as React from 'react'
import { withStyles } from '@material-ui/core'
import { SevereIllnessInsuranceCard } from '../InsurancesCardsPageRow'
import Collapsible from '../../Common/Collapsible'
import styles,{ Classes } from './styles'

export interface Props {
    classes: Classes
}

const SevereIllnessInsuranceFAQPageRow = (props: Props) => {
    const { classes } = props 
    return (
        <div>
            <div className={classes.title}>
                <SevereIllnessInsuranceCard className={classes.card}/>
            </div>
            <div className={classes.questions}>
                <Collapsible style={{ marginBottom:10 }} title='האם קיימת תקופת אכשרה בביטוח מחלות קשות?'>
                    <p>כן, קיימת תקופת אכשרה של כ-3 חודשים ברוב חברות הביטוח.
                    במידה ועושים שינוי של פוליסת מחלות קשות מחברה לחברה ניתן לרוב לבטל את תקופת האכשרה על הכיסויים החופפים.
                    </p>
                </Collapsible>
                <Collapsible style={{ marginBottom:10 }} title='ברכישת מחלות קשות, חברת הביטוח דורשות למלא הצהרת בריאות?'>
                    <p>כן, הצטרפות לפוליסת מחלות קשות חברות הביטוח דורשות למלא הצהרת בריאות, במידה אחת מהשאלות התשובה היא חיובית, חברת הביטוח רשאית להחריג מחלה מרשימת המחלות קשות, ליקר את הפוליסה ואף לדחות את בקשת המבוטח.</p>
                </Collapsible>
                <Collapsible style={{ marginBottom:10 }} title='האם יש כפל פיצוי מכמה חברות?'>
                    <p>כן, בפוליסת מחלות קשות ניתן לבטח בכמה חברות בקרות מקרה הביטוח ניתן לקבל מכמה חברות את הפיצוי עבור הכיסוי שנרכש.</p>
                </Collapsible>
                <Collapsible style={{ marginBottom:10 }} title='האם תקופת הביטוח לכל החיים?'>
                    <p>לא, תקופת הביטוח משתנה  בין חברה לחברה (לרוב זה עד גיל 75).</p>
                </Collapsible>
            </div>
        </div>
    )
}

export default withStyles(styles)(SevereIllnessInsuranceFAQPageRow)