import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core'
import AboutUsImage from '../../images/about-us-image.png'
import WhyUsImage from '../../images/why-us-image.png'
import {PageStack,PageRow,AltPageRow} from '../Common/PageStack'
import OurServicesPageRow from '../PageRows/OurServicesPageRow'
import TriangularBackgroundImage from '../../images/triangular-background.png'

const useStyles = makeStyles((theme: Theme) => createStyles({
    container:{
        width:'50%',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]:{
            width:'100%'
        }
    },
    image:{
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: 300,
        width: '90%',
        paddingRight: '5%',
        paddingLeft: '5%',
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            padding:0
        }
    },
    aboutUsImage:{
        backgroundImage: `url(${AboutUsImage})`,
    },
    whyUsImage:{
        backgroundImage: `url(${WhyUsImage})`,
    },
    text:{
        width: '90%',
        paddingRight: '5%',
        paddingLeft: '5%',
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            padding:0
        }
    },
    title:{
        color: theme.typography.h1.color,
        fontSize: theme.typography.h1.fontSize,
        fontWeight: theme.typography.h1.fontWeight,
    },
    description:{
        color: theme.typography.h3.color,
        fontSize: theme.typography.h3.fontSize,
        fontWeight: theme.typography.h3.fontWeight,
    },
    triangularBackground:{
        backgroundColor:'F2F2F2',
        height:100,
        backgroundImage: `url(${TriangularBackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
    },
}))


const About = () => {
    const classes = useStyles()
    
    return (
        <PageStack>
            <AltPageRow style={{paddingTop:60, paddingBottom:60}}>
                <OurServicesPageRow/>
            </AltPageRow>
            <div className={classes.triangularBackground}></div>
            <PageRow style={{paddingBottom:60, paddingTop:60}} rowStyle={{backgroundImage:"linear-gradient(#F2F2F2,#FFFFFF)"}}>
                <div className={classes.container}>
                    <div className={classes.text} style={{textAlign:'center'}}>
                        <h1 className={classes.title}>קצת עלינו</h1>
                        <h3 className={classes.description}>
                        Smart Insurance משווים ומוזילים ביטוחים של חברות הביטוח הגדולות בישראל: מגדל, הראל, איילון, מנורה, פניקס ועוד.
                        </h3>
                        <h3 className={classes.description}>
                        אנו מאגדים עשרות סוכני ביטוח וותיקים ומנוסים ובעלי כל הכלים לבדוק שהביטוחים שלכם  מתאימים לכם ולמשפחתכם.
                        </h3>
                    </div>
                </div>
                <div className={classes.container}>
                    <div className={classes.image + ' ' + classes.aboutUsImage}/>
                </div>
            </PageRow>
            <AltPageRow style={{paddingTop:60, paddingBottom:60}}>
                <div className={classes.container} style={{float:'left'}}>
                    <div className={classes.text} style={{textAlign:'center'}}>
                        <h1 className={classes.title}>למה אנחנו</h1>
                        <h3 className={classes.description}>
                        נבצע עבורכם בדיקה והשוואה בין הביטוחים הקיימים שלכם בהתאם לנתונים שתזינו באתר.
                        מה כוללת הבדיקה? הוזלת דמי ניהול, מניעת כפל ביטוחי, הוזלת פרמיות ואף בדיקת אפשרות לשדרוג הפוליסות הקיימות.
                        </h3>
                        <h3 className={classes.description}>
                        השימוש בטכנולוגיות מתקדמות בשילוב צוות מומחים מיומן, מאפשרים לנו לתת לכם מענה מקצועי ומהיר בתחום הביטוח.
                        </h3>
                    </div>
                </div>
                <div className={classes.container}>
                    <div className={classes.image + ' ' + classes.whyUsImage}/>
                </div>
            </AltPageRow>
        </PageStack>
    )
}

export default About