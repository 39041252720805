import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root:{
        color:theme.palette.text.primary
    }
}))

export interface LegalFormProps{

}

const LegalForm = (props: LegalFormProps) => {
    const css = useStyles()
    return(
        <div className={css.root} id="scroll-dialog-description" dir='rtl'>
            <div style={{paddingBottom:15}}>1.	שלום רב וברוכים הבאים לאתר האינטרנט Smart Insurance, אשר בבעלות רותם אוסי, ע.מ 203627617 והמופעל בכתובת האינטרנט www.smart-insurance.co.il (להלן: "האתר"). האתר הוקם ומנוהל על ידי בית העסק Smart Insurance, מרחוב ילדי טהרן 10, ראשון לציון (להלן: "הנהלת האתר").</div>
            <div style={{paddingBottom:15}}>2.	האתר מספק השוואת והוזלת מחירי ביטוחים של חברות הביטוח הגדולות בישראל, ובין השאר, מגדל, הראל, איילון, מנורה ועוד. האתר מאגד סוכני ביטוח בעלי כלים להתאים למשתמשי האתר את הביטוחים המתאימים והנכונים ביותר להם ולבני משפחותיהם. כמו כן, האתר משמש ו/או עשוי לשמש זירת פרסום בעבור חברות וסוכני ביטוח, נותני שירותים משלימים וגורמים נוספים.</div>
            <div style={{paddingBottom:15}}>3.	הנהלת האתר מאשרת לך גישה, עיון ושימוש באתר, בשירותי האתר, במידע ובתכניו בכפוף להסכמתך ואישורך לתנאים המפורטים להלן בתנאי שימוש אלו (להלן - "תנאי השימוש"). אתה מתבקש לקרוא היטב את תנאי השימוש שלהלן ולפעול על פיהם. האמור בתנאי שימוש אלו חל על כל שימוש שייעשה באתר וכל גישה או כניסה לאתר, על כל דפיו, שירותיו, המידע בו, תכניו והאפשרויות שבו, והוא מחייב את כל מי שבוחר לעשות שימוש כלשהו באתר (ו/או בדפיו ו/או בשירותיו ו/או במידע ובתכנים המופיעים בו) ו/או לגשת לאתר ו/או להיכנס לאתר ו/או להזמין ולרכוש מוצר או שירות כלשהו באמצעות האתר אם וככל שהאתר יאפשר רכישת מוצרים או שירותים. </div>
            <div style={{paddingBottom:15}}>4.	באם אינך מסכים לתקנון האתר ותנאיו ו/או באם אינך בגיר (בן למעלה מ-18), בעל כשרות משפטית, תעודת זהות ישראלית, כתובת, תיבת דואר וכרטיס אשראי תקף -  אינך רשאי לעיין ו/או להיכנס ו/או לעשות שימוש כלשהו באתר, בכל אחד מדפיו, שירותיו, המידע בו, ותכניו לכל מטרה שהיא וכן אינך רשאי למסור פרטים באתר ו/או לבצע באתר ו/או באמצעותו הזמנה או רכישה של מוצרים או שירותים כלשהם אם וככל שיפורסמו באתר. </div>
            <div style={{paddingBottom:15}}>5.	מומלץ לשוב ולעיין בתקנון זה מעת לעת על מנת להתעדכן בשינויים שחלו בו. הנהלת האתר שומרת לעצמה את הזכות לשנות ו/או לעדכן ו/או להוסיף ו/או לגרוע מהוראות תקנון זה, לפי שיקול דעתה, בלא צורך במסירת הודעה מראש או בדיעבד לך ובמידה ותחליט הנהלת האתר לעשות כן תקנון אתר מעודכן יפורסם באתר. מובן, שבמידה ואינך מסכים עם אחד מתנאי התקנון המעודכנים, הנך נדרש שלא לעשות כל שימוש באתר, לרבות בכל אחד מדפיו, שירותיו, המידע בו, ותכניו, לכל מטרה שהיא. </div>
            <div style={{paddingBottom:15}}>6.	המשך השימוש ו/או הביקור ו/או העיון ו/או הצפייה ו/או הכניסה ו/או ההתחברות לאתר, לשירותיו, למידע בו ולתכניו, לרבות לביצוע הזמנות ורכישות באתר ו/או באמצעותו, לאחר פרסום תנאי שימוש מעודכנים ו/או לאחר פרסום מדיניות פרטיות עדכנית (לפי העניין), מהווים את הסכמתך לתנאי השימוש המעודכנים ו/או למדיניות הפרטיות המעודכנת (לפי העניין) והתחייבותך לפעול על פיהם. </div>
            <div style={{paddingBottom:15}}>7.	כל האמור בתקנון זה להלן מנוסח מטעמי נוחות בלשון זכר, אולם הוא מתייחס לשני המינים בצורה שווה. בכל מקרה של סתירה או חוסר התאמה בין האמור באתר או בפרסומים מטעם ובקשר לאתר לבין האמור בתקנון זה, יגבר ויחול האמור בתקנון זה. </div>
            <div style={{paddingBottom:15}}>8.	לכל שאלה או הבהרה בנוגע לאמור בתקנון זה או בקשר למסירת פרטים באתר ו/או בקשר לשירותי האתר ו/או בקשר להזמנה ורכישה של מוצרים או שירותים באתר או באמצעותו או בכל עניין אחר, הנך מוזמן לפנות אל הנהלת האתר באמצעות העמוד "צור קשר".</div>
            <h3>תנאי השימוש וההשתתפות באתר ובשירותיו</h3>
            <div style={{paddingBottom:15}}>9.	בעצם גלישתך ו/או כניסתך ו/או שימושך ו/או עיונך באתר ושימוש בשירותיו, לרבות במתן פרטים ו/או ברכישה ו/או הזמנת מוצרים ושירותים באתר אם וככל יאפשר האתר הזמנת מוצרים ו/או שירותים  הנך מסכים ומקבל את כל התנאים וההוראות האמורים לעיל ולהלן. </div>
            <div style={{paddingBottom:15}}>10.	אין לעשות באתר ושירותיו כל שימוש מסחרי מכל סוג שהוא אלא שימוש אישי בלבד ולמטרות אישיות בלבד. ככל וברצונך לעשות באתר שימוש מסחרי כלשהוא ו/או לרכוש ו/או להזמין מוצרים או שירותים שיוצעו באתר לצורך מסחרי כלשהו, אנא פנה אל הנהלת האתר באמצעות פרטי ההתקשרות המופיעים בהמשך תקנון זה או על גבי האתר תחת העמוד "צור קשר".</div>
            <div style={{paddingBottom:15}}>11.	נאסר בזאת במפורש לנצל את האתר ו/או את תכניו ו/או שירותיו ו/או המוצרים והשירותים המפורסמים בו ו/או מידע אודות גולשים אחרים ו/או מפרסמים לצורך שליחת הודעות או דואר פרסומי ו/או מסחרי ו/או שיווקי לצד שלישי כלשהו (ולרבות, מבלי לגרוע, לגולשי האתר, למשתמשים בו ולמפרסמים באתר). אין לנקוט בכל פעולה כדי לקדם תכנים של הגולשים או של צדדים שלישיים או כדי לזכות ליתרון מסחרי. </div>
            <div style={{paddingBottom:15}}>12.	אין לעשות באתר, בתכניו, בשירותיו ו/או במידע המופיע בו, כל שימוש למטרות בלתי חוקיות.</div>
            <div style={{paddingBottom:15}}>13.	אתה מסכים לא להעביר, לא למסור, לא לאפשר גישה או שימוש, לא לתרגם, לא לעבד, לא להעתיק, לא לפרסם, לא לצלם, לא לשדר, לא להעמיד לרשות הציבור ולא לבצע בפומבי, תוכן כלשהו מתכני האתר ו/או שירות כלשהו משירותי האתר ו/או מידע כלשהו מהמידע המצוי והמפורסם באתר לרבות תכנים שהועלו על ידי גולשים באתר כגון המלצות על שירותי האתר בכל אמצעי שהוא ובכל מדיה כלשהי.</div>        
            <div style={{paddingBottom:15}}>14.	השימוש באתר, בתכניו, בשירותיו, ובמידע בו, יעשה על ידך אך ורק באמצעות דפדפן אינטרנט סטנדרטי ובשימוש אנושי וסביר. כמו-כן, חל איסור חמור לחדור לאתר ו/או לשירותי האתר ו/או למתחמים הדורשים רישום ו/או השארת פרטים באתר ו/או לשרתי האתר ו/או לכל חומר באתר או לקבצי מחשב באתר שלא כדין ו/או ללקט מהאתר מידע באמצעות תוכנות אוטומטיות או כל יישום דומה ו/או לנצל את האתר לצורך הפעלת או שליחת וירוס, תוכנות עוינות או כל יישום אחר, ידני או אוטומטי, העלולים להזיק לאתר ו/או לצדדים שלישיים או למנוע מהם לעשות שימוש. כל שינוי, הנדסה חוזרת או עדכון של כל חלק באתר זה, בשירותיו, בתכניו (לרבות, מבלי לגרוע, בקורסים הדיגיטלים המאופסנים בו), אסורים.</div>
            <div style={{paddingBottom:15}}>15.	מבלי לגרוע מכלליות האמור, אתה מסכים שלא לעקוף ו/או להשבית ו/או להפריע באמצעות דרך טכנולוגית ו/או אחרת, לאבטחה של שירותי האתר, תכני האתר, האתר ו/או המידע בו. כמו-כן יודגש שאתה מסכים שלא לעקוף ו/או להפריע ו/או לחדור לשירותי האתר ו/או לתכני האתר והמידע בו ו/או לאתר ו/או לחלקים באתר, שנמנעה ו/או הוגבלה הרשאתך ו/או אפשרותך לשימוש, לגישה, לכניסה, לצפייה ו/או לעיון בהם.</div>
            <div style={{paddingBottom:15}}>16.	בנוסף לסעדים המגיעים להנהלת האתר על פי כל דין, הנהלת האתר שומרת לעצמה את הזכות למנוע ו/או לחסום ו/או להשעות ו/או להפסיק כניסה ו/או גישה ו/או שימוש של גולש מסוים ו/או משתמש רשום באתר ו/או מפרסם מסוים לאתר ו/או לתכניו ו/או לשירותיו (כולם או חלקם) ו/או למידע המופיע בו -  באם בהתאם לשיקול דעתה הבלעדי של הנהלת האתר אותו גולש, משתמש רשום או מפרסם לא מילא אחר מלוא התנאים לעיל ולהלן ובכל מקרה שהשימוש באתר, בתכניו, בשירותיו ובמידע המופיע באלו ייעשה באופן בלתי ראוי ובלתי סביר, והכול על פי שיקול דעתה הבלעדי של הנהלת האתר. </div>
            <div style={{paddingBottom:15}}>17.	במקרה בו נמנעה ו/או הושעתה ו/או נחסמה ו/או הופסקה גישתך ו/או כניסתך ו/או שימושך, במקרים האמורים דלעיל, הרי שאתה מסכים כי לא תהא לך כל טענה ו/או דרישה ו/או תביעה כלשהי מכל סוג ומין שהוא, כלפי הנהלת האתר ו/או מי מטעמה.</div>
            <div style={{paddingBottom:15}}>18.	הנהלת האתר רשאית לסרב ליתן שירות לגולש אשר השאיר פרטים באתר ובכלל זה לבצע בדיקה והשוואת ביטוחים, למכור או להשלים עסקה להזמנה של שירותים או מוצרים אם וככל שיוצעו באתר בכל עת וללא צורך בהנמקה מוקדמת ו/או בהודעה מוקדמת. הנהלת האתר רשאית להסיר כל מידע ו/או תוכן ו/או מוצר ו/או שירות להימנע מפרסומם בשלמותם או בחלקם, בכל עת, לפי שיקול דעתה וללא מתן הודעה ו/או נימוק.</div>
            <h3>העדר אחריות</h3>
            <div style={{paddingBottom:15}}>19.	הנהלת האתר מציעה השוואה והוזלה של ביטוחים משתלמים של חברות הביטוח הגדולות בישראל תוך התאמה לגיל, סטטוס, עיסוק והצרכים האישיים של המבוטח בקשר לביטוחי בריאות, ביטוח חיים, ביטוח תאונות אישיות, ביטוח אובדן כושר עבודה, ביטוח מחלות קשות וביטוח סיעודי. </div>
            <div style={{paddingBottom:15}}>20.	האתר איננו חברת ביטוח ואיננו מוכר פוליסות ביטוח. האתר מעבד באמצעות סוכן חכם את המידע הנמסר על ידי המשתמש באתר בקשר למאפיינים האישיים, המצב האישי והרפואי שלו, הצרכים שלו ופוליסות הביטוח שברשותו על מנת להעביר אליו באמצעות סוכני ביטוח המאוגדים באתר הצעות מחיר לשדרוג פוליסות קיימות ו/או רכישת פוליסות ביטוח מוזלות.</div>
            <div style={{paddingBottom:15}}>21.	הצעות המחיר של פוליסות הביטוח נעשית על בסיס המידע שמסר הגולש באמצעות סוכני ביטוח עצמאיים המאוגדים באתר. לאתר אין ולא תהיה כל אחריות בקשר להתאמת הפוליסות שיוצעו על ידי סוכני הביטוח למטרות המשתמש ולא תהא למשתמש בעניין זה כל טענה ו/או דרישה ו/או תביעה מן הנהלת האתר. </div>
            <div style={{paddingBottom:15}}>22.	מובהר בזה כי אין בשירותי האתר, משום המלצה, עצה מקצועית, חוות דעת, ייעוץ ביטוחי או ייעוץ אחר בקשר לרכישה ו/או שדרוג ו/או עדכון ו/או ביטול של פוליסות ביטוח וכל התקשרות של משתמש בפוליסת ביטוח מול חברת ביטוח או ביטול פוליסה קיימת על בסיס הצעת מחיר שתועבר אליו מסוכן ביטוח עקב מסירת פרטים באתר  – הם באחריות הבלעדית של המשתמש והמשתמש מוותר על כל טענה ו/או דרישה ו/או תביעה בעניין כלפי הנהלת האתר. </div>
            <div style={{paddingBottom:15}}>23.	הגלישה והשימוש בתכני האתר, בשירותיו, ובכל רכיביו ותכניו של האתר ושירותיו השונים הנה באחריות המשתמש בלבד. על המשתמש לעשות באתר שימוש סביר וזהיר בלבד. אין לראות בכל פרסום ו/או מידע ו/או תוכן ו/או סקירה ו/או טיפ ו/או קישור ו/או המלצה כתחליף לבדיקה עצמאית ו/או התאמתם למשתמש או למשתמש הרשום, לצרכיו ו/או למטרותיו. </div>
            <div style={{paddingBottom:15}}>24.	האתר, ושירותיו מוצעים וניתנים למשתמשים כמות שהם (As Is). המשתמש מודע ומסכים לכך כי הנהלת האתר לא יכולה להתחייב ואינה מתחייבת כי לא יפלו באתר, בשירותיו, בתכניהם ובמידע בהם - אי דיוקים כלשהן, טעויות (בין טכניות ובין טעויות בתוכן ובמידע עצמו), שיבושים, השמטות או כי תוכן, מידע ושירות כלשהו המפורסמים באלו יתאימו לצרכיו או למטרותיו של משתמש או משתמש רשום כלשהו.</div>
            <div style={{paddingBottom:15}}>25.	הנהלת האתר לא תישא בכל אחריות לנזק או הפסד בגין התאמת שירותים ו/או מוצרים שהאתר מציע לגולשים ו/או בגין התאמת האתר ושירותיו למחשבם האישי ו/או בגין נזקים, תקלות, או כשלים בתוכנה, בשרת או בכל שירות הניתן על ידי צדדים שלישיים בו האתר תלוי לצורך הפעלתו או תפעולו. המשתמש מתבקש לקחת זאת בחשבון בבואו לעשות שימוש באתר, בשירותים המוצעים בו ובתכניו ו/או בבואו להסתמך על תכני ושירותי האתר ו/או בבואו לרכוש ולהזמין מוצרים, שירותים וקורסים באתר ו/או באמצעותו ו/או בבואו לצפות ולהשתמש בקורסים דיגיטליים באתר. </div>
            <div style={{paddingBottom:15}}>26.	הנהלת האתר לא תהא אחראית ולא תישא, במישרין או בעקיפין, בכל נזק, עקיף או ישיר, כספי, נפשי, גופני או אחר, אשר ייתכן וייגרם למשתמש כלשהו ו/או למי מטעמו כתוצאה ו/או בעקבות ו/או באמצעות שימוש המשתמש באתר ו/או הסתמכותם ו/או צפייתם ו/או עיונם ו/או שימושם באתר, בשירותי האתר, בתכני האתר, במידע באלו או בתוכניהם וחומריהם - כולם או חלקם.</div>
            <div style={{paddingBottom:15}}>27.	הנהלת האתר אינה מתחייבת לשמור גיבוי של כל תוכן המאוחסן ו/או מופיע באתר. כמו כן, הנהלת האתר רשאית להשבית את האתר, שירותיו ותכניו - כולם או חלקם - לצורך תחזוקתם, עדכונם, שדרוגם או לכל צורך אחר ולא תישא באחריות לכל נזק שיגרם כתוצאה מכך. </div>
            <div style={{paddingBottom:15}}>28.	הנהלת האתר אינה מתחייבת כי האתר, תכניו, שירותיו ותוכניו - לא יופרעו, יינתנו כסדרם, בבטחה, בלא הפסקות או הפרעות וכי הם חסינים מפני פריצה או גישה בלתי מורשית כלשהי. לפיכך, הנהלת האתר לא תהא אחראית לאי נוחות, עוגמת נפש או לכל נזק, ישיר או עקיף או אחר, עקב התרחשות אחד מהמקרים המנויים בסעיף זה.</div>
            <div style={{paddingBottom:15}}>29.	הנהלת האתר תהא רשאית בכל עת ועל פי שיקול דעתה הבלעדי לחדול מלאפשר את השימוש באתר, שירותיו, תכניו, והמידע של אלו -באופן זמני או לצמיתות, ובמקרים אלו הנהלת האתר ו/או מי מטעמה לא יהיו אחראים לאי נוחות, עוגמת נפש או לכל נזק, ישיר או עקיף או אחר במקרה כגון דא. </div>
            <h3>רישום לאתר</h3>
            <div style={{paddingBottom:15}}>30.	על מנת ליהנות משירותי האתר ובכלל זה קבלת שירותי השוואה והוזלה של פוליסות ביטוח מסוכני ביטוח המאוגדים באתר יש לבצע רישום לאתר. לצורך ביצוע הרישום יש לפעול כדלקמן: ראשית, יש ללחוץ על כפתור "התחל תהליך" ולבחור את סוג הביטוח הרלוונטי; שנית, יש למסור פרטים רלוונטית בהתאם לסוג הביטוח ובכלל זה מין, טווח גיל, נטילת תרופות, ניתוחים, נכויות, מגבלות, סוג עבודה, מחלות קשות, עישון וכיוצא באלו.</div>
            <div style={{paddingBottom:15}}>31.	שנית, יש למסור שם מלא וטלפון נייד. לאחר מסירת מספר הטלפון ישלח לנרשם באמצעות הודעת S.M.S קוד אישי אותו יידרש המשתמש להקיש באתר לצורך השלמת תהליך הרישום. שליחת הקוד האישי והדרישה והקיש אותו באתר נועדה למנוע אפשרות של רישום לאתר באמצעות רובוטים ו/או בוטים ו/או באמצעות צד שלישי שאיננו הנרשם ומבטיחה כי שירותי האתר יינתנו אך רק לגורמים אשר התכוונו למסור את פרטיהם, מעוניינים בשירותי האתר ומסכימים כי האתר יעביר את הפרטים שמסרו לסוכני ביטוח המאוגדים באתר ו/או למטרות אחרות כמפורט בתקנון האתר.</div>
            <div style={{paddingBottom:15}}>32.	מובהר כי על הפרטים שימסרו ע"י משתמש במהלך הליך ההרשמה, להיות נכונים, מדויקים ואמיתיים. שימוש בפרטים מפוברקים ו/או כוזבים ו/או לא נכונים ו/או פרטים אשר אינם שייכים למשתמש יגרמו לקבלת הצעות בלתי רלוונטיות ולא מתאימות למשתמש. בכל מקרה בו יתברר להנהלת האתר כי משתמש מסר פרטים לא נכונים יביא הדבר באופן מיידי לביטול ולהסרת המשתמש על כל נתונים אודותיו, זאת ללא כל הנמקה או הודעה מוקדמת. </div>
            <div style={{paddingBottom:15}}>33.	מובהר כי שירותי האתר הם אישיים ואין לבצע רישום לאתר עבור צד שלישי ללא ידיעתו ו/או הסכמתו ו/או רשותו וממילא במקרה כזה לא יושלם תהליך הרישום מאחר ורישום מחייב כאמור הכנסת קוד סודי הנשלח באמצות S.M.S לטלפון הנייד שנמסר במהלך תהליך ההרשמה. מבלי לגרוע מכל זכות או סעד המוקנים להנהלת האתר על פי דין, בכל מקרה של הפרת סעיף זה, תהיה הנהלת האתר רשאית לחסום את הגישה לאתר לגורם אשר ניסה לבצע רישום של צד שלישי וזאת ללא הודעה מראש.</div>
            <h3>הסכמה מפורשת להעברת פרטים לסוכני ביטוח המאוגדים באתר ו/או צדדים שלישיים אחרים </h3>
            <div style={{paddingBottom:15}}>34.	במידה ונרשמת לאתר והשלמת את תהליך הרישום הכולל הזנת קוד סודי הרי שאתה מסכים באופן מפורש כי הנהלת האתר תעביר את הפרטים שמסרת לסוכני ביטוח המאוגדים באתר ו/או לסוכנים אחרים ו/או לצדדי ג' אחרים וכי אלו יהיו רשאים לפנות אליך, בין באמצעות דוא"ל, ב-SMS ובטלפון, ולהציע לך לשדרג ו/או לרכוש פוליסות ביטוח ו/או שירותים אחרים בתחום הביטוח ו/או שירותים ומוצרים אחרים ו/או להעביר אליך באמצעות SMS, דוא"ל וטלפון חומר פרסומי אודות מוצרים ו/או שירותים ו/או מבצעים ו/או פעילויות של בקשר לביטוח, חברות ביטוח או מוצרים או שירותים אחרים מכל מן וסוג שהוא וללא הגבלה.</div>
            <div style={{paddingBottom:15}}>35.	מובהר, כי כל הפרטים והמידע שיועבר אליך בקשר לשירותי ביטוח ו/או שירותים אחרים יתקבלו מטעם סוכני הביטוח או הפונים בלבד ועל אחריותם. אין הנהלת האתר מחווה עליהם דעה או המלצה כלשהי ואין הנהלת האתר יכולה לערב על תוכנם, אמינותם, דיוקם, שלמותם ומהימנותם של השירותים או התאמתם והנהלת האתר לא תישא בכל אחריות בגין פוליסות ביטוח אשר ירכשו ו/או ישודרגו ו/או יבוטלו על ידי משתמש ו/או בגין הפרת כל מצג ו/או התחייבות ו/או הצהרה של מי מהפונים למשתמש. הנהלת האתר איננה ולא תהייה צד לכל ההתקשרות בין המשתמשים באתר לבין סוכן ביטוח ו/או חברת ביטוח כלשהיא.</div>
            <h3>נוהל הודעה והסרה</h3>
            <div style={{paddingBottom:15}}>36.	באתר תכנים וחומרים שמתקבלים ממפרסמים ו/או שותפים ו/או גורמים נוספים, לרבות המלצות,  חוות דעת ותוכן שיווקי ופרסומי המועלים לאתר בהתאם להצהרתם כי המידע הנו נכון והתכנים והחומרים אינם מפרים כל זכות של צד שלישי ובפרט כי התכנים והחומרים אינם מפרים זכויות יוצרים ופרטיות של מצולמים. אין באפשרות הנהלת האתר לוודא, והוא איננו מוודא בפועל, את נכונות הצהרה זו. האתר לא יישא בכל אחריות לכל נזק או הפסד שייגרמו לצד שלישי כלשהו כתוצאה מהפרת זכויות כלשהן. </div>
            <div style={{paddingBottom:15}}>37.	במידה והנך סבור כי תוכן שהתפרסם באתר פוגע או מפר זכות שלך, תוכל ליצור קשר עם האתר באמצעות העמוד "צור קשר", ולפרט את מהות הפגיעה. בפנייתך, אנא צרף קישור לעמוד הרלוונטי, וכן כתובת דואר אלקטרוני ופרטים נוספים של הפונה לצורך יצירת קשר במידת הצורך. ללא הפרטים האמורים, לא נוכל לבדוק את פנייתך. האתר יפעל כמיטב יכולתו לבדוק את הפנייה בהקדם האפשרי ויפעל בהתאם לשיקול דעתו ו/או עפ"י כל דין.</div>
            <h3>מפרסמים באתר</h3>
            <div style={{paddingBottom:15}}>38.	האתר עשוי לאפשר לבעלי עסקים שונים, תמורת תשלום, להציב פרסומות שונות ברחבי האתר ו/או מוצרים למכירה באתר ו/או באמצעותו (להלן: "המפרסמים").</div>
            <div style={{paddingBottom:15}}>39.	מובהר, כי כל הפרטים והמידע בנוגע למפרסמים לרבות דיוק פרטיהם, הכשרתם ופעילותם, כמו גם חומרים פרסומיים אחרים המתפרסמים באתר, מתפרסמים מטעם המפרסמים בלבד ועל אחריותם. אין הנהלת האתר מחווה עליהם דעה או המלצה כלשהי ואין הנהלת האתר יכולה לערוב על תוכנם, אמינותם, דיוקם, שלמותם ומהימנותם והנהלת האתר לא תישא בכל אחריות בגין פרסומים אלו ו/או בגין הפרת כל מצג ו/או התחייבות ו/או הצהרה של מי מהמפרסמים וכן הנהלת האתר לא תהיה צד לכל ההתקשרות בין הגולשים לבין מפרסם כלשהו.</div>
            <div style={{paddingBottom:15}}>40.	על כן, מומלץ לגולשים באתר לערוך בדיקה עצמאית בטרם יתקשרו עם מי המפרסמים או יסתמכו על מידע שפורסם בכל מקום אחר באתר. שכן כאמור האתר אינו יכול ואינו מתיימר להעניק כל אחריות באשר לכל מידע ו/או עסק ו/או שירות ו/או טובין המפורסמים באתר, הן לגבי טיבם והן באשר לתוכנם. </div>
            <div style={{paddingBottom:15}}>41.	מובהר כי פרסום חומר פרסומי באתר ו/או מוצר ו/או שירות המוצעים למכירה באתר - אין משמעו כי הנהלת האתר או מי מטעמה מעודד או ממליץ על המפרסם, המוצר או על השירות המפורסמים. על הגולשים לעשות בדיקה עצמאית, כצרכנים סבירים, בטרם יתקשרו עם מי מבעלי המקצוע ו/או ירכשו מוצר או שירות באתר ובאמצעותו. המלצה זו מקבלת משנה תוקף בכל הנוגע למידע מקצועי או מוצרים לשימוש מקצועי כלשהו.</div>
            <div style={{paddingBottom:15}}>42.	אין לראות בכל פרסום ו/או מידע ו/או סקירה ו/או טיפ ו/או קישור ו/או המלצה ו/או חוות דעת, אם וככול יפורסמו באתר (ו/או בתכניו, במידע בו ו/או בשירותיו), כתחליף לבדיקה עצמאית ו/או התאמתם למשתמש.</div>
            <h3>מדיניות פרטיות - הגנת הפרטיות ושימוש במידע</h3>
            <div style={{paddingBottom:15}}>43.	האתר מכבד את פרטיותם של הגולשים באתרו ורואה בשמירה על פרטיות זו ערך רב. להלן, תוכלו למצוא את מדיניות הפרטיות של האתר, תוך פירוט המידע הנאסף על ידנו אודות הגולשים באתר, כיצד אנו משתמשים בו ושומרים אותו, לאילו גורמים מועבר על ידינו המידע ואילו אפשרויות יש לגולשים להגן על פרטיותם בקשר לכך במידה והם אינם מעוניינים בהעברת המידע.</div>
            <div style={{paddingBottom:15}}>44.	בעת ההרשמה לקבלת שירותי השוואת והוזלת פוליסות ביטוח באתר, וכן בעת ביצוע הזמנת ורכישת מוצרים ו/או שירותים באתר אם וככל יתאפשרו בעתיד, אתם נדרשים למסור פרטים אישיים, הכוללים שם מלא, טלפון, טווח גיל, טווח השתכרות ונתונים אישיים ורפואיים נוספים בהתאם לסוג הביטוח הרלוונטי. </div>
            <div style={{paddingBottom:15}}>45.	אתה מצהיר ומאשר בזאת כי פרטים אלה ניתנים מרצונך החופשי ובהסכמתך המלאה וכי ידוע לך שפרטים אלו, וכן מידע נוסף שאיננו אישי שיצטבר אודות הגולשים, יישמר במאגר המידע של הנהלת האתר ויועבר על ידה לסוכני ביטוח המאוגדים באתר לצורך מתן הצעות לשדרוג ו/או רכישה של פוליסות ביטוח ו/או לצדדי ג' לצורך הצעת שירותים ו/או מוצרים משלימים בקשר לביטוח ו/או בתחומים אחרים. </div>
            <div style={{paddingBottom:15}}>46.	בשימושך באתר ובמתן הפרטים אתה מסכים מפורשות לכך שהנהלת האתר תהייה רשאית לאסוף ולשמור מידע בקשר אליך שייאסף במסגרת שימושך באתר, להעביר את פרטיך לסוכני ביטוח המאוגדים באתר ו/או לצדדים שלישיים על מנת לפנות אליך, בין באמצעות מספר הטלפון שמסרת באתר ובין באמצעים אחרים, ולהציע לך לשדרג ו/או לרכוש פוליסות ביטוח וכן להעביר אליך חומר פרסומי אודות מוצרים ו/או שירותים מבצעים ו/או פעילויות בין של הנהלת האתר ובין של שותפיה העסקיים וכל מידע אחר בו סבורה הנהלת האתר כי תמצא בו עניין מכוח השימוש באתר ו/או הזמנה ורכישת של מוצרים, שירותים. בידך הברירה, בכל עת, כמפורט להלן, להיגרע ממאגר האתר ו/או להפסיק לקבל חומר פרסומי שישלח על ידי מפרסמים כולם ו/או חלקם ע"י מילוי אחר הוראות הנלוות להודעות שישלחו. </div>
            <div style={{paddingBottom:15}}>47.	למען הסר ספק מובהר ומודגש כי באישורך את תנאי תקנון האתר אתה נותן את הסכמתך המלאה והמפורשת להנהלת האתר לעשות שימוש במידע שייאסף על ידה למטרות שיווק ומכירה ובכלל זה לגלות או למכור את המידע לצדדים שלישיים בישראל או מחוץ לישראל לרבות מפרסמים, סוכני ביטוח, חברות ביטוח, ספקי אשראי, גורמים פרטיים, חברות וארגונים למטרות ושלא למטרות רווח למטרות שיווק ומכירה, מתן הטבות, יצירת קשר, שליחת עדכונים, פניה וזיהוי, אימות פרטים, דיוור ישיר, עידוד רכישת מוצרים ו/או שירותים, פילוח ומחקר סטטיסטי וכן לכל מטרה אחרת. </div>        
            <div style={{paddingBottom:15}}>48.	במידה ואינך מעונין שהמידע אותו אתה נדרש למסור יישמר אודותיך הנך מתבקש שלא למסור פרטים אישיים באתר, לא לעשות שימוש בשירותי האתר המחייבים מתן פרטים וכן לא להשלים את תהליך הרישום באתר. מובהר עוד כי פרטי משתמש נשמרים באתר אך ורק אם הוא משלים את תהליך הרישום ומכניס את הקוד הסודי הנשלח אליו באמצעות S.M.S. בכל שלב בתהליך הרישום אתה רשאי להתחרט, לא להשלים את תהליך הרישום לאתר ובמקרה שכזה כל הנתונים שנמסרו על ידך ימחקו באופן אוטומטי ולא יעשה בהם כל שימוש על ידי הנהלת האתר. </div>
            <div style={{paddingBottom:15}}>49.	כמו כן, ובהתאם לדין הנכם רשאים בכל עת לעיין במידע שנאסף אודותיך המוחזק במאגרי המידע של הנהלת החברה וכן לפנות לאתר באמצעות דואר אלקטרוני ולבקש כי פרטיכם יתוקנו אם וככל שמצאת כי אינם נכונים או מדויקים או כי יימחקו מהמאגר. האתר מאשר ומתחייב בזאת כי בתוך 3 ימי עבודה מכל פניה כאמור ימחקו לאלתר כל פרטי המשתמש מן האתר ולעדכן את המשתמש כי הפרטים נמחקו מן המאגר של הנהלת האתר. כמו כן, במקרה בו אינך מעוניין לקבל דואר פרסומי, דיוור ישיר או פרסומות אתה יכול להודיע על כך להנהלת האתר באמצעות דואר אלקטרוני והנהלת האתר תפעל לגריעתך מרשימות  התפוצה.</div>
            <div style={{paddingBottom:15}}>50.	בזמן הגלישה והשימוש באתר, בתכניו, בשירותיו, ובמידע ובתכנים באלו, מצטבר במאגרי המידע של האתר מידע על הרגלי הגלישה שלך, אשר יכלול, בין היתר: את התכנים והשירותים בהם התעניינת, הדפים באתר בהם צפית, הפרסומות שקראת וצפית בהן, כתובת האינטרנט (כתובת IP) ממנה ניגשת לאתר, המיקום הגיאוגרפי ממנו נגשת לאתר, כמה זמן שהית באתר בשירותיו ובכל אחד מדפיו, ואילו פעולות ביצעת בו. מידע זה יישמר במאגרי המידע של הנהלת האתר.</div>
            <div style={{paddingBottom:15}}>51.	למעט המידע אשר נמסר על ידך מרצונך החופשי ובהסכמתך להנהלת האתר על דרך של מילוי פרטיך באתר ובשירותיו, אשר מידע זה מזהה אישית אותך, שאר המידע אשר נצבר על ידי הנהלת האתר הינו ברובו מידע סטטיסטי כללי שאינו מזהה אותך באופן אישי והוא נועד לשפר את רמת השירותים והתכנים המוצעים על ידי הנהלת האתר וליצירת שירותים, פרסומים ותכנים חדשים בהתאם להתעניינות הגולשים.</div>
            <div style={{paddingBottom:15}}>52.	האתר שומר לעצמו את הזכות לעשות שימוש במידע שנאסף לצורך פיקוח, בקרה, עדכון, שיפור, הפקת מסקנות ולצרכים סטטיסטיים. ייתכן ובנסיבות מסוימות ישתמש האתר ב"עוגיות" (Cookies), שהינם קבצים קטנים הנשלחים למחשבך. אמצעים אלה נדרשים לצורך תפעולו השוטף והתקין, ובכלל זה כדי לאסוף נתונים סטטיסטיים אודות השימוש באתר, לאימות פרטים, כדי להתאים את האתר להעדפותיך האישיות ולצורכי אבטחת מידע. במקרה הצורך, ייתכן ומידע סטטיסטי, אשר אינו מזהה אותך באופן אישי, יועבר לשם ניתוח לצד שלישי, למען המשך פיתוחו ותפעולו השוטף של האתר, שירותיו ושל פעילות הנהלת האתר.</div>
            <div style={{paddingBottom:15}}>53.	נוסף על האמור, האתר עושה גם שימוש בקבצי COOKIES ("עוגיות") של צדדים שלישיים (בין היתר, Google, Facebook ואחרים)- וזאת על מנת ליידע את הנהלת האתר אודות התנועה באתר, על מנת להקל עליכם את הגלישה באתר, על מנת לבצע אופטימיזציה של דפי אינטרנט ושירותים המוצגים באתר, על מנת לזהותכם כגולשי האתר לצרכי פרסום ו/או שיווק וכן על מנת להציג לכם ו/או להפנות אליכם מודעות, מידע ומסמכים (בין באתר ובין מחוץ לאתר ברחבי רשת האינטרנט) על סמך ביקוריכם הקודמים באתר. </div>
            <div style={{paddingBottom:15}}>54.	לתשומת לבך, במידה ואינך מעוניין בקבלת "עוגיות" או במידה ואינך מעוניין כי האתר יאסוף מידע אודותיך, באפשרותך לשנות את הגדרת דפדפן האינטרנט בו הנך משתמש או לחלופין להפסיק לעשות שימוש באתר וכן לא לבצע בו או באמצעותו פעולה כלשהיא.</div>
            <div style={{paddingBottom:15}}>55.	במידה שהנהלת האתר תחליט למכור את פעילות האתר ו/או את האתר לגוף מסחרי אחר תישמר לה הזכות להעביר את המידע במאגרי המידע של האתר לאותו גוף מסחרי בתנאי שתנאי הפרטיות המפורטים בתנאי שימוש אלו יישמרו ויהיו מקובלים על אותו גוף מסחרי עימו היא תתקשר.</div>
            <div style={{paddingBottom:15}}>56.	מסירת המידע באתר ואיסופו הם תהליכים מאובטחים באמצעות פרוטוקול הצפנה. הנהלת האתר נוקטת בנוסף במגוון אמצעים שונים לשמור המידע המאוחסן אצלה ועל פרטיותם של המשתמשים. עם זאת, הנהלת האתר אינה יכולה להבטיח הגנה הרמטית ומוחלטת ואינה יכולה להבטיח או לערוב לכך כי המידע לא ייחשף על ידי פעולה זדונית או מכוונת נגד האתר וכי צד שלישי אשר הצליח להיחשף למידע כזה או אחר לא יעשה בו שימוש. </div>
            <div style={{paddingBottom:15}}>57.	במידה ואינכם מעוניינים כי מידע אודותיכם ו/או אודות השימוש שלכם באתר יישמר, הנכם מתבקשים שלא לעשות שימוש באתר, בשירותיו, בתכניו ולהימנע מרישום לאתר. הנהלת האתר לא תהיה אחראית לנזק מכל מין ומכל סוג שהוא, עקיף או ישיר, שייגרם כתוצאה מפריצה למאגר הנתונים ו/או לאתר ו/או למחשביה בזדון על ידי פצחנים או צדדים שלישיים.</div>
            <h3>קישורים (LINKS)</h3>
            <div style={{paddingBottom:15}}>58.	הימצאותם של קישורים ("לינקים") לאתרים אחרים באתר, בתכניו, בשירותיו ו/או במידע בו, אינם מהווים ערובה לכך שהאתרים פעילים ולתכנים באתרים אלה מבחינת מהימנותם, אמינותם, שלמותם, או מכל בחינה אחרת. הנהלת האתר אינה מתחייבת כי הקישורים יובילו לאתר פעיל ותקין והנהלת האתר רשאית לשנות ולהסיר קישורים אלו לפי שיקול דעתה הבלעדי. הנהלת האתר לא תהא אחראית לכל נזק, ישיר עקיף או אחר, שיגרם למשתמש ו/או למי מטעמו, כתוצאה מהשימוש או מההסתמכות על התכנים, השירותים והמידע המופיע באתרים אלו ו/או בשל תנאי השימוש שלהם ו/או בשל מדיניות השמירה על הפרטיות שלהם. כל כניסה ו/או שימוש לאתרים אחרים שאינם בבעלות הנהלת האתר תהייה כפופה לתנאי השימוש השמורה לאתרים אלו ולמדיניות הפרטיות שלהם, שאין להנהלת האתר כל אחריות בגינם.</div>
            <h3>קניין רוחני</h3>
            <div style={{paddingBottom:15}}>59.	זכויות היוצרים ויתר זכויות הקניין הרוחני באתר (לרבות, מבלי לגרוע, בטקסטים, בעיצוב האתר, בעימודו, בגרפיקה, בצילומים, באיורים, בלוגו האתר, בשם האתר, בדומיין שלו, במידע הטכנולוגי הדרוש להפעלתו, ביישומים, קודי המחשב ו/או כל חומר אחר הכלול בו), בתכני האתר, במידע באתר בשירותיו ובקורסים הדיגיטליים, בשירותי האתר, במוצרים ושירותים אשר יוצעו להזמנה או רכישה באתר ובכל תוכן הכלול בהם  (להלן: "נכסי קניין רוחני"), הינם הינן וימשיכו להיות בבעלות מלאה של הנהלת האתר ו/או בבעלות צד שלישי שהרשה להנהלת האתר להשתמש בו. סימן המסחר "Smart Insurance" הוא סימן מסחר בבעלות של הנהלת האתר (להלן: "סימני הנהלת האתר").</div>
            <div style={{paddingBottom:15}}>60.	אין לעשות כל שימוש בנכסי קניין רוחני של הנהלת האתר ו/או בסימני הנהלת האתר, לרבות בשם האתר, מבלי לקבל לכך רישיון מפורש בכתב ומראש מאת הנהלת האתר. אין להעתיק, להוריד, לשכפל, לצלם, לתרגם, לעבד, ליצור יצירות נגזרות, לשחזר, לפרסם, להפיץ, לבצע בפומבי, לשדר, למכור, או להעמיד לרשות הציבור או להציג כל חלק מאתר זה (ו/או מתכניו, שירותיו והמידע בו), מהקורסים הדיגיטליים (ו/או מתוכניהם, חומריהם ו/או הקשור בהם), לרבות בתוך מסגרת (Frame), גלויה או סמויה ללא הסכמתה הכתובה מראש של הנהלת האתר או מבעלי הזכויות האחרים, לפי העניין ובכפוף לתנאי ההסכמה (אם וככל שתינתן).</div>
            <div style={{paddingBottom:15}}>61.	אתה מאשר כי לא תעשה כל פעולה ו/או מעשה ו/או מחדל, בניגוד לדיני קניין רוחני ו/או בלתי חוקי ו/או שעלול לפגוע או להפר את זכויות היוצרים וזכויות הקניין הרוחני של הנהלת האתר ו/או של צד שלישי אחר. </div>
            <h3>שרותים בתשלום</h3>
            <div style={{paddingBottom:15}}>62.	בשלב זה, כל השירותים המוצעים באתר למשתמשים הפרטיים והביתיים הנם ללא תשלום.</div>
            <div style={{paddingBottom:15}}>63.	עם זאת, האתר שומר לעצמו את הזכות להחליט, בכל עת, כי שירות מסויים, כולו או חלקו, יותנה בתשלום מראש.</div>
            <h3>יצירת קשר</h3>
            <div style={{paddingBottom:15}}>64.	במידה ותתעורר כל שאלה בנוגע לתקנון זה או בכל נושא אחר, צוות האתר ישמח לעמוד לרשותכם. </div>
            <div style={{paddingBottom:15}}>65.	תוכלו ליצור עימנו קשר הן באמצעות פנייה בכתב דרך העמוד "צור קשר", הן בדואר אלקטרוני לכתובת insurance@smart-agent.co.il הן באופן טלפוני בשעות הפעילות (א'-ה' בין השעות 9:00- 19:00) למספר טלפון: 052-897-5001.</div>
            <div style={{paddingBottom:15}}>66.	כתובתנו למשלוח דואר הינו: Smart Insurance, מרחוב ילדי טהרן 10, ראשון לציון</div>
            <h3>שונות</h3>
            <div style={{paddingBottom:15}}>67.	הנהלת האתר תהיה רשאית לשנות בכל עת את תנאי השימוש ללא הודעה מראש או בדיעבד.</div>
            <div style={{paddingBottom:15}}>68.	הנהלת האתר תהיה רשאית להעביר את האתר לכל כתובת אחרת, לשנות את שמו או להפסיק את פעילותו ושירותיו, לרבות הקורסים המוצעים בו, באופן מלא, חלקי או בכל דרך אחרת כפי שתראה לנכון, בכל עת, ובהתאם לשיקול דעתה הבלעדי של הנהלת האתר. </div>
            <div style={{paddingBottom:15}}>69.	מבלי לגרוע מהאמור בתנאי השימוש, המשתמש מסכים בזאת, כי תקופת ההתיישנות לגבי כל תביעה ו/או דרישה כנגד האתר ו/או הנהלת האתר, תוגבל לתקופה של 6 חודשים והצדדים רואים בכך כהסכם לתקופת ההתיישנות כמשמעו בסעיף בחוק ההתיישנות, תשי"ח-1958.</div>
            <div style={{paddingBottom:15}}>70.	כל עילה משפטית הנובעת מהפעילות ו/או השימוש באתר, בשירותי האתר, בקורסים הדיגיטליים, בתכני האתר, בפרסומים בו, במידע בו ומהזמנות והרכישות באתר או באמצעותו, לרבות כל עילה משפטית הנוגעת לתוקפם ופרשנותם של תנאי שימוש אלה יוכפפו לדין הישראלי בלבד וסמכות השיפוט הבלעדית בכל הנוגע לאלו תהא נתונה לבתי המשפט המוסמכים במחוז מרכז.</div>
            <div style={{paddingBottom:15}}>71.	היה ויקבע שחלק כלשהו מתנאי שימוש אלה אינו תקף או שאינו בר אכיפה, אזי הסעיפים שנשלל תוקפן או שהוחלט כי אינם אכיפים ייחשבו כמוחלפים בסעיפים תקפים ואכיפים, אשר תוכנם תואם במידת הקירוב הגדולה ביותר את כוונת הסעיפים המקוריים ויתר סעיפי תנאי שימוש אלו יישארו בתוקפם.</div>
            <div style={{paddingBottom:15}}>72.	רישומי מערכות המחשוב של הנהלת האתר, בדבר הפעולות המתבצעות על ידך באתר ו/או בשירותיו ו/או בקורסים הדיגיטליים ו/או בתכני האתר ו/או בכל רכיב באתר, ולרבות בדבר כך כי אשרת את תנאי שימוש אלו ובדבר הזמנותיך ורכישותיך באתר ובאמצעותו ו/או בדבר גישתך שימושך וצפייתך בקורסים הדיגיטליים ו/או תוכניהם, יהוו ראייה לנכונות הפעולות וכן ראייה בדבר אישורך לתנאי שימוש אלו – וזאת, אלא אם הוכח אחרת על ידך. </div>        
        </div>
    )
}

export default LegalForm