import { Theme } from "@material-ui/core"

const styles = (theme: Theme) => ({
    title:{
        paddingTop: 20,
        width: 'calc(50% - 60px)',
        marginRight: '30px',
        marginLeft: '30px',
        [theme.breakpoints.up('xs')]:{
            float:'left'
        },
        [theme.breakpoints.down('sm')]:{
            width: '100%',
            marginRight: '0px',
            marginLeft: '0px',
        }
    },
    questions:{
        paddingTop: 30,
        width: 'calc(50% - 60px)',
        marginRight: '30px',
        marginLeft: '30px',
        [theme.breakpoints.up('sm')]:{
            float:'right'
        },
        [theme.breakpoints.down('sm')]:{
            width: '100%',
            marginRight: '0px',
            marginLeft: '0px',
        }
    },
    listItem:{
        listStyle: 'disc',
        display: 'list-item',
        cursor: 'initial'
    },
    insurances:{
        [theme.breakpoints.up('sm')]:{
            width: 'calc(50% - 20px)'
        }
    },
    link:{
        display: 'inline-block',
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        cursor:'pointer',
        '&:hover': {
            opacity:'50%'
            }
    },
    card:{
        width: '100%',
        margin: 'auto'
    }
})

export interface Classes {
    title: string
    questions: string
    listItem: string
    insurances: string
    link: string
    card: string
}

export default styles