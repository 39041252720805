import * as React from 'react'
import './App.css'
import { BrowserRouter, Route } from 'react-router-dom'
import LandingPage from '../Pages/LandingPage'
import { AppHeader, AppFooter } from '.'
import WhatsUpSVGLink from '../../images/whats-app.svg'
import * as Pages from '../Pages'
import PageHeader from './PageHeader'
import PageFooter from './PageFooter'
import { ThemeProvider } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { ICampaignParams, IReduxState } from '../../reducers'
import { useRef, useState } from 'react'
import { Theme, withStyles } from "@material-ui/core/styles";
import AccessibilityDrawer from '../AccessibilityDrawer/AccessibilityDrawer'
import { Badge, Fab } from '@material-ui/core'
import { AccessibilityNewRounded} from '@material-ui/icons'
import theme from '../../theme'
import { initContact, setCampaignParams } from '../../actions'
import highContrastTheme from '../../highContrastTheme'
import { withInsurance } from '../HCO/withInsurance'
import location from '../../helpers/location'
import ContactUsPage from '../Pages/ContactUsPage'
import { InsuranceType } from '../../helpers/insurance'
import { IContact } from '../../api'

export interface Props {
    insurance?: InsuranceType
    fontSize: number
    highContrast: boolean
    campaign?: string
    initContact: (contact?: IContact) => void
    setCampaignParams: (campaignParams: ICampaignParams) => void
}

const App = (props: Props) => {
    const { fontSize, highContrast, initContact, setCampaignParams } = props
    const scrollRef = useRef<any>()
    const [openAccessibility, setOpenAccessibility] = useState(false)
    const [selected, setSelected] = useState(0)
    const searchQuery = window.location.search
    const urlSearParams = new URLSearchParams(searchQuery) 

    let campaign = ''

    if (urlSearParams.has('utm_campaign')){
        campaign = urlSearParams.get('utm_campaign') as string
    }
    else if (urlSearParams.has('Gclid')){
        campaign = 'default'
    }

    setCampaignParams({ campaign: props.campaign??campaign });

    initContact()

    const routings: React.ReactNode[] = [
        <Route key={0} exact path={location.root + '/'}  component={LandingPage}/>,
        <Route key={1} exact path={location.root + '/faq'} component={Pages.FAQPage}/>,
        <Route key={2} exact path={location.root + '/about'} component={Pages.AboutPage}/>,
        <Route key={3} exact path={location.root + '/contact_us'} component={ContactUsPage}/>
    ]

    const onNavigate = (index: number) => {
        scrollRef.current.scrollIntoView()
        setSelected(index)
    }

    const styles = (theme:Theme) =>({
        "@global":{
            html:{
                fontSize: fontSize + 'em',
                color: highContrast ? 'white' : undefined
            },
        }
    })

    const AppContent = withStyles(styles)((innerProps: any) =>{
        return (
        <div className='App'>  
            <div ref={scrollRef}></div>
            <AppHeader key={1} onNavigate={onNavigate} selected={selected}/>
            <div className={'App-body'}>
                <PageHeader/>
                {routings}
                <PageFooter className='page-row theme-page-row-color extra-padding'/>
            </div>
            <AppFooter className='page-row' onNavigate={onNavigate}/>
        </div>)
    })

    const isAccessibilityActive = () =>{
        return (fontSize > 1 || highContrast)
    }

    const isAccessibilityFeatureEnabled = false;

    return (
            <BrowserRouter>
                <ThemeProvider theme={highContrast ? highContrastTheme : theme }>
                    <AppContent />
                    <a style={{margin:10, position:'absolute', bottom:10, left:10}} href='https://wa.me/972556803939' target='_blank' rel='noopener noreferrer'>
                        <img alt='whatsApp' src={WhatsUpSVGLink}/>
                    </a>
                    {isAccessibilityFeatureEnabled ?
                    <>
                        <Fab size='small' style={{margin:10, position:'absolute', bottom:10, left:10, border:'3px solid white', backgroundColor:'black'}} color={'primary'} onClick={() => setOpenAccessibility(true)} aria-label="accessibility">
                            {isAccessibilityActive() ?
                                <Badge badgeContent={' '} color={'error'}>
                                    <AccessibilityNewRounded style={{color: 'white'}} />
                                </Badge> : 
                                <AccessibilityNewRounded style={{color: 'white'}} />}
                        </Fab>
                        <AccessibilityDrawer open={openAccessibility} anchor={'right'} onClose={() => setOpenAccessibility(false)}/>
                    </>:<></>}
                </ThemeProvider>
            </BrowserRouter>
        
    )
    
}

const mapStateToProps = (state: IReduxState) => {
    var props = {
        fontSize: state.accessibility.fontSize,
        highContrast: state.accessibility.highContrast,
        campaign: state.campaignParams?.campaign
    }
    return props;
}

export default connect(mapStateToProps, { initContact, setCampaignParams })(withInsurance(App))
