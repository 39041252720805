import * as React from 'react'
import { NavLink } from 'react-router-dom'
import LegalFormDialog from '../../LegalFormDialog'
import CopyrightsDialog from '../../CopyrightsDialog/CopyrightsDialog'
import { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Logo from '../../../images/logo.png'
import location from '../../../helpers/location'
import { API_URL } from '../../../env'
import AccessibilityStatementDialog from '../../AccessibilityStatementDialog/AccessibilityStatementDialog'

const useStyles = makeStyles((theme: any) => ({
    root: {
        display: 'table',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
        width: '100%'
    },
    container: {
        width: 'calc(33% - 20px)',
        paddingLeft: 10,
        paddingRight: 10,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)'
        }
    },
    logoContainer: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 10,
        paddingRight: 10,
        width: 'calc(33% - 20px)',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)'
        }
    },
    copyrightContainer: {
        width: 'calc(100% - 20px)',
        paddingLeft: 10,
        paddingRight: 10,
        direction: 'rtl'
    },
    logo: {
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: 100,
        marginBottom: 20,
        backgroundImage: `url(${Logo})`
    },
    text: {
        color: theme.palette.primary.contrastText,
        display: 'inline-block',
        margin: 8
    },
    link: {
        color: theme.palette.primary.contrastText,
        display: 'inline-block',
        margin: 8,
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.5
        }
    }
}))

export interface Props {
    className?: string,
    onNavigate: (index: number) => void
}

const AppFooter = (props: Props) => {
    const [openLegalForm, setOpenLegalForm] = useState(false)
    const [openCopyrights, setOpenCopyrights] = useState(false)
    const [openAccessibilityStatement, setOpenAccessibilityStatement] = useState(false)
    const classes = useStyles()

    return (
        <footer className={classes.root + ' ' + props.className}>
            <div className={classes.container}>
                <h2>ראה עוד</h2>
                <NavLink className={classes.link} onClick={() => { props.onNavigate(0) }} to={location.root + '/'}>הסוכן החכם</NavLink><br />
                <NavLink className={classes.link} onClick={() => { props.onNavigate(1) }} to={location.root + '/faq'}>שאלות נפוצות</NavLink><br />
                <NavLink className={classes.link} onClick={() => { props.onNavigate(2) }} to={location.root + '/about'}>אודותינו</NavLink><br />
                <NavLink className={classes.link} onClick={() => { props.onNavigate(3) }} to={location.root + '/contact_us'}>צור קשר</NavLink><br />
                <button className={classes.link} onClick={() => setOpenLegalForm(true)} >תקנון האתר</button><br/>
                <button className={classes.link} onClick={() => setOpenAccessibilityStatement(true)} >הצהרת נגישות</button><br/>
                <LegalFormDialog open={openLegalForm} handleClose={() => setOpenLegalForm(false)} />
                <AccessibilityStatementDialog open={openAccessibilityStatement} handleClose={() => setOpenAccessibilityStatement(false)} />
            </div>
            <div className={classes.container}>
                <h2>צור קשר</h2>
                <p className={classes.text}>ילדי טהרן 10, ראשון לציון</p><br />
                <a className={classes.link} href='tel:+972528975001'>052-897-5001</a><br />
                <a className={classes.link} href='mailto:insurance@smart-agent.co.il'>insurance@smart-agent.co.il</a>
            </div>
            <div className={classes.logoContainer}>
                <div className={classes.logo} />
                <div>
                    <a className={classes.link} href='https://www.instantssl.com/' id='comodoTL' target='_blank' rel='noopener noreferrer'>
                        <img src={ API_URL + '/images/ssl' } alt='SSL Trust' />
                    </a>
                </div>
            </div>
            <div className={classes.copyrightContainer}>
                Smart Insurance - כל הזכויות שמורות 2020
                <button className={classes.link} onClick={() => setOpenCopyrights(true)}>© </button>
                <CopyrightsDialog open={openCopyrights} handleClose={() => setOpenCopyrights(false)} />
            </div>
        </footer>
    )
}

export default AppFooter