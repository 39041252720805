import { Button, TextField, Theme, useTheme, withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import { Checkbox, Form, Link } from '../Common'
import LegalFormDialog from '../LegalFormDialog'
import validator from 'validator'
import { sendTokenWithContact, updateContactForm } from '../../actions' 
import { connect } from 'react-redux'
import { IReduxState } from '../../reducers'
import { IContact } from '../../api'
import { withInsurance } from '../HCO/withInsurance'
import { InsuranceType } from '../../helpers/insurance'

const styles = (theme: Theme) =>({
    title:{
        color: 'white'
    },
    submit:{
        fontSize: '1.2em', 
        marginTop:20,
        marginBottom:20,
        padding: 0,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: 'white',
        color: '#165372',
        borderRadius: 10,
        border: '5px solid white',
        "&:hover": {
            backgroundColor: '#467E99',
            color: 'white'
          }
    },
    link:{
        marginRight:4,
        marginLeft:4,
        color: 'white'
    },
    input:{
        fontSize:'1.2em'
    }
})

const getInsuranceTitle = (insurance?: InsuranceType) =>{
    switch (insurance) {
        case 'health_insurance':
            return `בריאות`    
        case 'life_insurance':
            return `חיים`   
        case 'loss_of_work_fitness_insurance':
            return `אובדן כושר עבודה`
        case 'personal_accidents_insurance':
            return `תאונות אישיות`
        case 'severe_illness_insurance':
            return `מחלות קשות`
        default:
            return ``
    }   
}


interface Classes {
    title: string
    submit: string
    link: string
    input: string
}

interface Props{
    classes: Classes
    contactForm: IContact
    onSubmit: () => void
    sendTokenWithContact: (phoneNumber: string, contactForm: IContact) => void
    updateContactForm: (contactForm: IContact) => void
}

const ContactInformationForm = (props: Props) =>{
    const { classes, contactForm, updateContactForm, onSubmit, sendTokenWithContact } = props
    
    const [showLegalForm, setShowLegalForm] = useState(false)
    const [approveLegalForm, setApproveLegalForm] = useState(false)
    const [validationMode, setValidationMode] = useState(false)
    const [name, setName] = useState(contactForm.name || '')
    const [phoneNumber, setPhoneNumber] = useState(contactForm.phoneNumber || '')

    const handleNameChange = (e: any) =>{
        setName(e.target.value)
    }

    const handlePhoneNumberChange = (e: any) =>{
        setPhoneNumber(e.target.value)
    }

    const handleApproveLegalFormClick = (e: any) =>{
        setApproveLegalForm(e.target.checked)
    }

    const handLegalFormDialogClose = (result: boolean) =>{
        setShowLegalForm(false)
        setApproveLegalForm(result)
    }

    const handleSubmit = () => {
        setValidationMode(true)
        if (((name !== '') && (approveLegalForm) && (validator.isMobilePhone(phoneNumber, 'he-IL'))))
        {
            updateContactForm({ ...contactForm, phoneNumber, name })
            sendTokenWithContact(phoneNumber, { ...contactForm, phoneNumber, name })
            onSubmit() 
        }
    }

    const validatePhoneNumber = () => (!(validationMode && !validator.isMobilePhone(phoneNumber, 'he-IL')))  
    const validateName = () => (!(validationMode && name === ''))
    const validateApproveLegalForm = () => (!(validationMode && !approveLegalForm))

    const theme = useTheme()
    return(
        <Form onSubmit={handleSubmit} id='contactInformationForm' noValidate>
            <h1 className={classes.title}>{`להשוואת ביטוח`}<br/>{`${getInsuranceTitle(contactForm.insuranceType)}`}</h1>
            
            <TextField
                variant='outlined' name='name' type='text' placeholder='שם מלא'
                inputProps={{ style:{ backgroundColor:'white', borderRadius: 4 } }}
                onChange={handleNameChange}
                helperText={validateName() ? ' ' : 'שדה חובה'}
                error={!validateName()}/>
            
            <TextField
                variant='outlined' name='phoneNumber' type='number' placeholder='מספר סלולרי' 
                inputMode='tel'
                inputProps={{ pattern:'[0-9]*', style:{ backgroundColor:'white', borderRadius: 4 } }}
                onChange={handlePhoneNumberChange}
                helperText={(validatePhoneNumber()) ? ' ' : 'המספר אינו תקין'}
                error={!validatePhoneNumber()}/>

            <Typography style={{color:'white'}}>* אני מאשר כי קראתי את
                <Link className={classes.link} onClick={() => setShowLegalForm(true)}>התקנון</Link>
                ואני מסכים לתנאיו
                <Checkbox name='approveLegalForm'
                    checked={approveLegalForm}
                    style={{
                        color: (!validateApproveLegalForm()) ? 
                            theme.palette.error.main : 
                            'white'
                        }}
                    onClick={handleApproveLegalFormClick}/>
            </Typography>
            

            <LegalFormDialog withConfirmation open={showLegalForm} 
                handleClose={handLegalFormDialogClose}/>

            <Button className={classes.submit} variant='contained' color='primary' type='submit'>
                אימות באמצעות SMS
            </Button>
        </Form>
    )
}

const mapStateToProps = (state:IReduxState) => {
    return ({
        contactForm: state.contactForm
    })
}

const ContactInformationFormWithStyles = withStyles(styles)(ContactInformationForm)
export default connect(mapStateToProps, { sendTokenWithContact, updateContactForm })(ContactInformationFormWithStyles)