import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { AppBar, IconButton, makeStyles, MenuItem, MenuList, Toolbar, useMediaQuery } from '@material-ui/core'
import { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import useClasses from '../../../classes'
import MenuIcon from '@material-ui/icons/Menu'
import { MenuOpen } from '@material-ui/icons/'
import { useTheme } from '@material-ui/core/styles'
import Logo from './Logo'
import location from '../../../helpers/location'

const useStyles = makeStyles(theme => ({
    root:{
        backgroundColor: theme.palette.primary.main, 
        [theme.breakpoints.up('sm')]:{
            boxShadow: 'none',
            height:50,
            position:'absolute'
        },
        [theme.breakpoints.down('xs')]:{
            height:'auto',
            position:'fixed'
        }
    },
    menuList:{
        outline:'none',
        [theme.breakpoints.up('sm')]:{
            display:'flex',
        }
    },
    manuButtonConteiner:{
        display:'flex',
        [theme.breakpoints.up('sm')]:{
            display:'none',
        }
    },
    menuButton:{
        float:'right',
    }
}))

export interface Props {
    onNavigate: (index: number) => void
    selected: number
}

const AppHeader = (props: Props)  => {
    const { onNavigate, selected } = props
    const dropdownMenu = useMediaQuery(useTheme().breakpoints.down('xs')); 
    const [openMenu, setOpenMenu] = useState(false)
    const headerRef = useRef<HTMLElement>(null)
    
    const getSelectedByUrl = () =>{
        let uri = window.decodeURI(window.location.toString())
        uri = uri.split('?')[0]
        if (uri.endsWith('/faq') || uri.endsWith('/faq/'))
            return [false, true, false, false]
        else if (uri.endsWith('/about') || uri.endsWith('/about/'))
            return [false, false, true, false]
        else if (uri.endsWith('/contact_us') || uri.endsWith('/contact_us/'))
            return [false, false, false, true]
        else 
            return [true, false, false, false]
    }

    useEffect(()=>{
        setSelectedMenu(getSelectedByUrl())
    },[selected])

    useEffect(() => {
        setOpenMenu(!dropdownMenu)
    },[dropdownMenu])

    useEffect(() => {
        window.addEventListener('mousedown', handleClickOutside)
        window.addEventListener('touchstart', handleClickOutside)
        return () => {
            window.removeEventListener('mousedown', handleClickOutside)
            window.removeEventListener('touchstart', handleClickOutside)
        }
      }, [])

    // this code is for handling the close the click outside menu event
    // for closing the menu
    const handleClickOutside = (event: Event) => {
        if ((headerRef.current != null) && !headerRef.current.contains(event.target as Node)) {
            setOpenMenu(false)
        }
    }

    const [selectedMenu,setSelectedMenu] = useState(getSelectedByUrl())
    const classes = useClasses()
    const css = useStyles()

    return (
        <AppBar ref={headerRef} className={classes.pageRows.primary + ' ' + css.root}>
            <MenuList className={css.menuList} style={{direction:'rtl', paddingTop:0, float:'right'}}>
                <div className={css.manuButtonConteiner}>
                    <IconButton className={css.menuButton} onClick={() => setOpenMenu(!openMenu)} edge="start" color="inherit" aria-label="open drawer">
                        {openMenu ? <MenuOpen/>:<MenuIcon/>}
                    </IconButton>
                    <Toolbar style={{flex:1}}/>
                    <Logo onNavigate={() => onNavigate(0)} style={{ marginTop:5 }}/>
                </div>
                <MenuItem selected={selectedMenu[0]} style={{height:50, padding:0, display: ((!dropdownMenu) || (openMenu)) ? 'flex' : 'none' }}>
                    <NavLink className={classes.links.contrast} 
                        style={{height:'100%', width:'100%', paddingTop:25, paddingLeft:25, paddingRight:25, textAlign:'center'}}
                        onClick={() => { setSelectedMenu([true, false, false, false]); setOpenMenu(false); onNavigate(0) }} 
                        to={location.root + '/'}>
                        הסוכן החכם
                    </NavLink>
                </MenuItem>
                <MenuItem selected={selectedMenu[1]} style={{height:50, padding:0, display: ((!dropdownMenu) || (openMenu)) ? 'flex' : 'none'}}>
                    <NavLink className={classes.links.contrast} 
                        style={{height:'100%', width:'100%', paddingTop:25, paddingLeft:25, paddingRight:25, textAlign:'center'}}
                        onClick={() => { setSelectedMenu([false, true, false, false]); setOpenMenu(false); onNavigate(1) }} 
                        to={location.root + '/faq'}>
                        שאלות נפוצות
                    </NavLink>
                </MenuItem>
                <MenuItem selected={selectedMenu[2]} style={{height:50, padding:0, display: ((!dropdownMenu) || (openMenu)) ? 'flex' : 'none'}}>
                    <NavLink className={classes.links.contrast} 
                        style={{height:'100%', width:'100%', paddingTop:25, paddingLeft:25, paddingRight:25, textAlign:'center'}}
                        onClick={() => { setSelectedMenu([false, false, true, false]); setOpenMenu(false); onNavigate(2) }} 
                        to={location.root + '/about'}>
                        אודותינו
                    </NavLink>
                </MenuItem>
                <MenuItem selected={selectedMenu[3]} style={{height:50, padding:0, display: ((!dropdownMenu) || (openMenu)) ? 'flex' : 'none'}}>
                    <NavLink className={classes.links.contrast} 
                        style={{height:'100%', width:'100%', paddingTop:25, paddingLeft:25, paddingRight:25, textAlign:'center'}}
                        onClick={() => { setSelectedMenu([false, false, false, true]); setOpenMenu(false); onNavigate(3) }} 
                        to={location.root + '/contact_us'}>
                        צור קשר
                    </NavLink>
                </MenuItem>
                <Toolbar style={{flex:1, display: (!dropdownMenu) ? 'flex' : 'none'}}/>
                <Logo onNavigate={() => onNavigate(0)} style={{ marginTop:5, display: (!dropdownMenu) ? 'flex' : 'none' }}/>
            </MenuList> 
        </AppBar>
    )
}

export default AppHeader