import React, { useState } from 'react'
import Reviews from '../../Reviews'
import ContactFormDialog from '../../ContactForm/ContactFormDialog'
import { withInsurance } from '../../HCO/withInsurance'
import { InsuranceType } from '../../../helpers/insurance'
import { Button, Theme, withStyles } from '@material-ui/core'

const styles = (theme: Theme) => ({
    button:{ 
        backgroundColor: '#F76C05',
        color: 'white',
        borderRadius: 15,
        border: '3px solid white',
        fontSize:'1.1em',
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 30,
        marginBottom: 60,
    }
})

const getInsuranceTitle = (insurance?: InsuranceType) =>{
    switch (insurance) {
        case 'health_insurance':
            return `להשוואת ביטוח בריאות`    
        case 'life_insurance':
            return `להשוואת ביטוח חיים`   
        case 'loss_of_work_fitness_insurance':
            return `להשוואת ביטוח אובדן כושר עבודה`
        case 'personal_accidents_insurance':
            return `להשוואת ביטוח תאונות אישיות`
        case 'severe_illness_insurance':
            return `להשוואת ביטוח מחלות קשות`
        default:
            return `להשוואת ביטוחים מיידית`
    }   
}

interface Classes {
    button: string
}

export interface Props {
    className?: string
    classes: Classes
    insurance?: InsuranceType
}

const PageFooter = (props: Props) => {
    const [startProcess, setStartProcess] = useState(false)
    return (
        <div className={props.className}>
            <Reviews/>
            <Button variant="contained" className={props.classes.button} onClick={() => setStartProcess(true)}>
                {getInsuranceTitle(props.insurance)}
            </Button>
            <ContactFormDialog insurance={props.insurance} open={startProcess} onClose={() => setStartProcess(false)}/>      
        </div>
    )
}

export default withStyles(styles)(withInsurance(PageFooter))
