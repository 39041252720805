import { Tab, Tabs, Paper, Theme, makeStyles, createStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { TabPanel } from '../Common'
import ContactInformationForm from '../NewContactForm/ContactInformationForm'
import TokenVerificationForm from './TokenVerificationForm'
import PostContactFormResult from './PostContactFormResult'
import InsuranceSelectionForm from './InsuranceSelectionForm'
import { withInsurance } from '../HCO/withInsurance'
import { updateContactForm, createContactForm, initContact } from '../../actions' 
import { connect } from 'react-redux'
import { IContact } from '../../api'
import { InsuranceType } from '../../helpers/insurance'
import { IReduxState } from '../../reducers'

const useStyles = makeStyles((theme: Theme) =>createStyles({
    root:{
        marginLeft:50,
        marginRight:50,
        paddingLeft:40, 
        paddingRight:40,
        paddingBottom:20,
        width:'calc(100% - 180px)',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 50,
        opacity: 0.9,
        [theme.breakpoints.down('xs')]:{
            marginLeft:5,
            marginRight:5,
            width:'calc(100% - 90px)'
        }
    }
}))

interface Props{
    insurance?: InsuranceType
    updateContactForm: (contactForm: IContact) => void
    createContactForm: (contactForm?: IContact) => void
    initContact: (contactForm?: IContact) => void
}

const ContactForm = (props: Props) =>{
    const { insurance, updateContactForm, createContactForm } = props
    const [value,setValue] = useState((insurance !== undefined) ? 1 : 0)
    const [isPosted, setIsPosted] = useState(false)

    const classes = useStyles()

    useEffect(() => {
        createContactForm()
        updateContactForm({ insuranceType: insurance })
    }, [createContactForm, updateContactForm, insurance])
    
    const handleTabChange = (newValue: number) => {
        setValue(newValue)
    }

    return(
        <Paper className={classes.root} elevation={3}>        
            <div>
                <Tabs value={value} style={{display:'none'}}>
                    <Tab tabIndex={0}/>
                    <Tab tabIndex={1}/>
                    <Tab tabIndex={2}/>
                </Tabs>
                <TabPanel index={0} value={value}>
                    <InsuranceSelectionForm onSubmit={() => { handleTabChange(value + 1) }}/>
                </TabPanel>
                <TabPanel index={1} value={value}>
                    <ContactInformationForm onSubmit={() => { handleTabChange(value + 1) }}/>
                </TabPanel>
                <TabPanel index={2} value={value}>
                    <TokenVerificationForm onSubmit={(postResult: boolean) => { 
                        setIsPosted(postResult)
                        initContact()
                        handleTabChange(value + 1)
                    }}/>
                </TabPanel>
                <TabPanel index={3} value={value}>
                    <PostContactFormResult postResult={isPosted} />
                </TabPanel>
            </div>
        </Paper>
    )

}

const ContactFormWithInsurance = withInsurance(ContactForm)

export default connect(null, { updateContactForm, createContactForm, initContact })(ContactFormWithInsurance)