import React from 'react'
import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => createStyles({
    root:{
        textAlign:'center'
    },
    title:{
        color: theme.typography.h1.color,
        fontSize: theme.typography.h1.fontSize,
        fontWeight: theme.typography.h1.fontWeight,
    },
    description:{
        color: theme.typography.h2.color,
        fontSize: theme.typography.h2.fontSize,
        fontWeight: theme.typography.h2.fontWeight,
    },
  }))  

const YourQuiteSoulPageRow = () =>  {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <h1 className={classes.title} >
                השקט הנפשי שלכם
            </h1>
            <h2 className={classes.description} style={{fontWeight:700}}>
                כולל מתן מענה ועזרה מיידית בזמן אמת,<br/>
                וגם חסכון משמעותי בכסף שאתם משלמים מדי חודש
            </h2>
        </div>
    )
}

export default YourQuiteSoulPageRow

