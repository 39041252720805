import React from 'react'
import { PageStack, AltPageRow } from '../Common/PageStack'
import OurServicesPageRow from '../PageRows/OurServicesPageRow'

const ContactUsPage = () => {

    return (
        <PageStack>
            <AltPageRow style={{paddingTop:50}}>
                <OurServicesPageRow/>
            </AltPageRow>
        </PageStack>
    )
}

export default ContactUsPage