import React from 'react'
import { PageStack, PageRow, AltPageRow } from '../Common/PageStack'
import { createStyles, Link, makeStyles, Theme, withStyles } from '@material-ui/core'
import { ContactUsPageRow } from '../PageRows'
import { withInsurance } from '../HCO/withInsurance'
import { PersonalAccidentsInsuranceFAQPageRow, HealthInsuranceFAQPageRow, 
    SevereIllnessInsuranceFAQPageRow, LifeInsuranceFAQPageRow,
    LossOfWorkFitnessInsuranceFAQPageRow } from '../PageRows'
import { InsuranceType } from '../../helpers/insurance'
import TriangularBackgroundImage from '../../images/triangular-background.png'
import OurServicesPageRow from '../PageRows/OurServicesPageRow'

const useStyles = makeStyles((theme:Theme) => createStyles({
    title:{
        color: theme.typography.h1.color,
        fontSize: theme.typography.h1.fontSize,
        fontWeight: theme.typography.h1.fontWeight,
    },
    triangularBackground:{
        backgroundColor:'F2F2F2',
        height:100,
        backgroundImage: `url(${TriangularBackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '100% 100%',
    },
}))


const FAQHeaderLink = withStyles((theme: Theme) =>({
    root:{
        cursor: 'pointer'
    }
}))(Link)

FAQHeaderLink.defaultProps = {
    variant:'h2',
    color:'primary',
    underline:'always'
}

interface Props{
    insurance: InsuranceType
}

const FAQPage = ({ insurance }: Props) => {
    const classes = useStyles()

    const lifeInsuranceFAQREF: React.Ref<HTMLDivElement> = React.createRef();
    const personalAccidentsInsuranceFAQREF: React.Ref<HTMLDivElement> = React.createRef();
    const severeIllnessInsuranceFAQREF: React.Ref<HTMLDivElement> = React.createRef();
    const healthInsuranceFAQREF: React.Ref<HTMLDivElement> = React.createRef();
    const lossOfWorkFitnessInsuranceFAQREF: React.Ref<HTMLDivElement> = React.createRef();

    const scrollRefIntoView = (ref: any) => {
        ref.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <PageStack>
            { (insurance === undefined) && 
                <>
                    <AltPageRow style={{ paddingBottom:30, paddingTop:30 }}>
                        <div style={{textAlign:'center'}}>
                            <div>
                                <div style={{marginLeft:8, marginRight:8}}>
                                    <h1 className={classes.title}>שאלות נפוצות</h1>
                                </div>
                                <div style={{margin:8}}>
                                    <FAQHeaderLink onClick={()=>{scrollRefIntoView(healthInsuranceFAQREF.current)}}>ביטוח בריאות</FAQHeaderLink>
                                </div>
                                <div style={{margin:8}}>
                                    <FAQHeaderLink onClick={()=>{scrollRefIntoView(lifeInsuranceFAQREF.current)}}>ביטוח חיים</FAQHeaderLink>
                                </div>
                                <div style={{margin:8}}>
                                    <FAQHeaderLink onClick={()=>{scrollRefIntoView(personalAccidentsInsuranceFAQREF.current)}}>ביטוח תאונות אשיות</FAQHeaderLink>
                                </div>
                                <div style={{margin:8}}>
                                    <FAQHeaderLink onClick={()=>{scrollRefIntoView(lossOfWorkFitnessInsuranceFAQREF.current)}}>ביטוח אובדן כושר עבודה</FAQHeaderLink>
                                </div>
                                <div style={{margin:8}}>
                                    <FAQHeaderLink onClick={()=>{scrollRefIntoView(severeIllnessInsuranceFAQREF.current)}}>ביטוח מחלות קשות</FAQHeaderLink>
                                </div>
                            </div>
                        </div>
                    </AltPageRow>
                    <div className={classes.triangularBackground}></div>
                    <div ref={healthInsuranceFAQREF}/>
                    <PageRow style={{paddingBottom:30, paddingTop:30}}>
                        <HealthInsuranceFAQPageRow/>
                    </PageRow>
                    <div ref={lifeInsuranceFAQREF}/>
                    <AltPageRow style={{paddingBottom:30, paddingTop:30}}>
                        <LifeInsuranceFAQPageRow/>
                    </AltPageRow>
                    <div ref={personalAccidentsInsuranceFAQREF}/>
                    <PageRow style={{paddingBottom:30, paddingTop:30}}>
                        <PersonalAccidentsInsuranceFAQPageRow/>
                    </PageRow>
                    <div ref={lossOfWorkFitnessInsuranceFAQREF}/>
                    <AltPageRow style={{paddingBottom:30, paddingTop:30}}>
                        <LossOfWorkFitnessInsuranceFAQPageRow/>
                    </AltPageRow>
                    <div ref={severeIllnessInsuranceFAQREF}/>
                    <PageRow style={{paddingBottom:30, paddingTop:30}}>
                        <SevereIllnessInsuranceFAQPageRow/>
                    </PageRow>
                    <div className={classes.triangularBackground} style={{ transform: 'rotate(180deg)' }}></div>
                    <AltPageRow style={{ paddingTop:60, paddingBottom:30 }}>
                        <OurServicesPageRow/>
                    </AltPageRow>
                </>}
            { (insurance !== undefined) && 
                <>
                    <AltPageRow>
                        <h1 className={classes.title} style={{textAlign:'center'}}>שאלות נפוצות</h1>
                    </AltPageRow>
                    <AltPageRow style={{paddingBottom:30}}>
                        {(insurance === 'health_insurance') &&<HealthInsuranceFAQPageRow/>}
                        {(insurance === 'life_insurance') &&<LifeInsuranceFAQPageRow/>}
                        {(insurance === 'loss_of_work_fitness_insurance') &&<LossOfWorkFitnessInsuranceFAQPageRow/>}
                        {(insurance === 'personal_accidents_insurance') &&<PersonalAccidentsInsuranceFAQPageRow/>}
                        {(insurance === 'severe_illness_insurance') &&<SevereIllnessInsuranceFAQPageRow/>}
                    </AltPageRow>
                    <AltPageRow>
                        <OurServicesPageRow/>
                    </AltPageRow>
                </>}
        </PageStack>
    )
}

export default withInsurance(FAQPage)