import { Theme, makeStyles, createStyles } from '@material-ui/core'
import React from 'react'
import { PageRow } from '../../Common'
import { withInsurance } from '../../HCO/withInsurance'
import BackgroundImage from '../../../images/top-view-parents-spending-time-with-kids.png'
import ContactForm from '../../NewContactForm/ContactForm'

const useStyles = makeStyles((theme: Theme) => createStyles({
    root:{
        marginTop:'50px',
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: 'calc(100vw / 1.85)',
        backgroundColor:'white',
        textAlign:'center',
        [theme.breakpoints.down(1440)]:{
            height: 750,
        }
    },
    contactForm:{
        width: 520,
        marginTop: -90,
        [theme.breakpoints.down('sm')]:{
            width: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    }
}))

const PageHeader = () => {
    const classes = useStyles()

    return(
        <PageRow className={classes.root}>
            <div className={classes.contactForm}>
                <ContactForm/>
            </div>
        </PageRow>
    )
}

export default withInsurance(PageHeader)
