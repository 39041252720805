import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core"
import * as React from "react"

const useStyles = makeStyles((theme:Theme) =>createStyles({
    root:{
        textAlign:'center'
    },
    title:{
        color: theme.typography.h1.color,
        fontSize: theme.typography.h1.fontSize,
        fontWeight: theme.typography.h1.fontWeight,
    },
    description:{
        color: theme.typography.h3.color,
        fontWeight: theme.typography.h3.fontWeight,
        fontSize: theme.typography.h3.fontSize
    }
}))

const AboutUsPageRow = () =>  {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <h1 className={classes.title}>מי אנחנו ?</h1>
            <h3 className={classes.description}>
                Smart Agent מציעה השוואה והוזלה של ביטוחים משתלמים של חברות הביטוח הגדולות בישראל<br/>
                תוך התאמה אישית לגיל, לסטטוס, לעיסוק ולצרכים האישיים שלכם.
            </h3>
        </div>
    )
}

export default AboutUsPageRow

