import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core"
import React from "react"
import CheckIconImg from "../../images/check.png"

const useStyles = makeStyles((theme: Theme) => createStyles({
    icon:{
        color: theme.palette.primary.main,
        paddingTop: 10 
    },
    container:{
        marginTop: 10,
        marginBottom: 50
    },
    title:{
        color: theme.typography.h1.color,
        fontSize: theme.typography.h1.fontSize,
        fontWeight: theme.typography.h1.fontWeight,
    },
    badgeText:{
        marginTop: 0,
        fontSize: 15,
        paddingLeft: 10,
        paddingRight: 10,
        color: theme.palette.primary.main
    },
    badgeDown:{
        [theme.breakpoints.up(1850)]:{
            marginTop: 50,
        },
    },
    badgeContainer:{
        width:'calc(100% / 7)',
        paddingTop: 20,
        display: 'flex',
        minWidth: 180,
        [theme.breakpoints.down(1850)]:{
            width:'calc(100% / 6)',
        },
        [theme.breakpoints.down(1700)]:{
            width:'calc(100% / 5)',
        },
        [theme.breakpoints.down(1400)]:{
            width:'calc(100% / 3)',
        },
        [theme.breakpoints.down(720)]:{
            minWidth: 0,
            paddingLeft:2,
            paddingRight:2,
            width:'calc((100% / 2) - 4px)',
        },
    },
    badgeContainerCenter:{
        width:'calc(100% / 7)',
        paddingTop: 20,
        display: 'flex',
        minWidth: 180,
        [theme.breakpoints.down(1850)]:{
            width:'calc(100% / 6)',
        },
        [theme.breakpoints.down(1700)]:{
            width:'calc(100% / 2)',
        },
        [theme.breakpoints.down(1400)]:{
            width:'calc(100% / 3)',
        },
        [theme.breakpoints.down(720)]:{
            minWidth: 0,
            paddingLeft:2,
            paddingRight:2,
            width:'calc((100% / 2) - 4px)',
        },
    },
    badgeContainerEnd:{
        paddingTop: 20,
        width:'calc(100% / 7)',
        display: 'flex',
        minWidth: 180,
        [theme.breakpoints.down(1850)]:{
            width:'calc(100% / 1)',
        },
        [theme.breakpoints.down(1700)]:{
            width:'calc(100% / 2)',
        },
        [theme.breakpoints.down(1400)]:{
            minWidth: 0,
            width:'calc(100% / 1)',
        },
    }
}))

export interface BadgProps {
    text: string
}

const Badge = (props: BadgProps) =>{
    const { text } = props
    const classes = useStyles()
    return (
        <div style={{ borderRadius:10, backgroundColor:'#C2DFED', width:170, minHeight:110, margin: 'auto' }}>
            <img className={classes.icon} src={CheckIconImg} width={32} height={25} style={{marginLeft:'auto', marginRight:'auto'}}/>
            <h3 className={classes.badgeText}>{text}</h3>
        </div>
    )
}


const ContactUsPageRow = () =>  {
    const classes = useStyles()
    return (
        <div className={classes.container} style={{textAlign:'center'}}>
            <h1 className={classes.title}>השירותים שלנו</h1>
            <div style={{textAlign:'center'}}>
                <div className={classes.badgeContainer}>
                    <Badge text={'בדיקת ביטוחים קיימים כולל ניתוח תיק מקיף'}></Badge>
                </div>
                <div className={classes.badgeContainer + ' ' + classes.badgeDown}>
                    <Badge text={'השוואת ביטוחים בין חברות הביטוח השונות'}></Badge>
                </div>
                <div className={classes.badgeContainer}>
                    <Badge text={'הוזלה משמעותית בעלויות הביטוח'}></Badge>
                </div>
                <div className={classes.badgeContainer + ' ' + classes.badgeDown}>
                    <Badge text={'מציאת החברה הכדאית ביותר לצרכים שלכם'}></Badge>
                </div>
                <div className={classes.badgeContainer}>
                    <Badge text={'שדרוג פוליסות קיימות'}></Badge>
                </div>
                <div className={classes.badgeContainerCenter + ' ' + classes.badgeDown}>
                    <Badge text={'מענה וטיפול בתביעות ביטוח'}></Badge>
                </div>
                <div className={classes.badgeContainerEnd}>
                    <Badge text={'שימוש בטכנולוגיות מתקדמות המאפשרות נגישות, נוחות ומהירות'}></Badge>
                </div>
            </div>
        </div>
    )
}

export default ContactUsPageRow


