import React from 'react'
import { Theme, withStyles } from '@material-ui/core'
import { InsuranceCompaniesLogosPageRow, InsurancesCardsPageRow, AboutUsPageRow, WhatWillYouGetPageRow, OurBestAgentsInOnePlacePageRow, HowItWorksPageRow } from '../PageRows'
import { PageStack, PageRow, AltPageRow } from '../Common/PageStack'
import TriangularBackgroundImage from '../../images/triangular-background.png'
import OurServicesPageRow from '../PageRows/OurServicesPageRow'
import YourQuiteSoulPageRow from '../PageRows/YourQuiteSoulPageRow'

const styles = (theme:Theme) =>({
    root:{
    },
    triangularBackground:{
        backgroundColor:'F2F2F2',
        height:100,
        backgroundImage: `url(${TriangularBackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '100% 100%',
    },
    dualColoredRow:{
        backgroundImage: "linear-gradient(90deg, #ECF1F4 25%, #3C748F 25%)",
        [theme.breakpoints.down('md')]:{
            backgroundImage:"linear-gradient(90deg, #3C748F 25%, #3C748F 25%)",
        },
    }

})

interface Props{
    classes: any,
}

const LandingPage = withStyles(styles)((props: Props) => {
    const {classes} = props
    
    return (    
        <PageStack classes={classes}>
            <AltPageRow>
                <OurServicesPageRow/>
            </AltPageRow>
            <div className={classes.triangularBackground}></div>
            <PageRow>   
                <AboutUsPageRow/>
                <InsuranceCompaniesLogosPageRow/>
            </PageRow>
            <PageRow style={{paddingBottom:30, paddingTop:30}} rowStyle={{backgroundImage:"linear-gradient(#F2F2F2,#FFFFFF)"}}>
                <YourQuiteSoulPageRow/>
            </PageRow>
            <AltPageRow style={{paddingBottom:30, paddingTop:30}}>
                <InsurancesCardsPageRow/>
            </AltPageRow>
            <PageRow className={classes.dualColoredRow}>
                <HowItWorksPageRow/>
            </PageRow>
        </PageStack>
    )
})

export default LandingPage
