import { createTheme, ThemeOptions }  from '@material-ui/core/styles'

const white = '#FFFFFF'
const blacksmoke = '#2e2e3d'
const whitesmoke = '#F2F2F2'

const main = '#1C5B7A'// '#127997'
const secondary = '#C2E4F0'// '#01BA9F'

const spacing = [4, 8, 12]
const themeOptions: ThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: main
    },
    secondary: {
      main: secondary,
    },
    text:{ primary: blacksmoke, secondary: white },
    background: { default: whitesmoke, paper:white },
  },
  typography:{
    fontFamily: "'Assistant',Sans-serif",
    h1:{ 
      fontSize: '2.8em', 
      color: '#E56D0C', 
      fontWeight: 700,
      ['@media (max-width:600px)']:{
        fontSize: '2em', 
      }
    },
    h2:{ 
      fontSize: '2em', 
      color: '#555555', 
      fontWeight: 700,
      ['@media (max-width:600px)']:{
        fontSize: '1.2em', 
      }
    },
    h3:{ 
      fontSize: '1.1em', 
      color: '#929292', 
      fontWeight: 400 
    }
  },
  spacing:(factore: number) => spacing[factore]
}

const createMyTheme = (options: ThemeOptions) => {
  return createTheme({
    ...options
    })
}

const theme = createMyTheme(themeOptions);

console.log(theme.breakpoints.down('xs'))

export default theme;