import gtm from 'react-gtm-module'
import { InsuranceType } from './insurance'
import { NODE_ENV } from '../env'

const initialize = () =>{
    if (NODE_ENV === 'Production'){
        gtm.initialize({ gtmId: 'GTM-KXFW6LL' })
    }
}

const trigger = (dataLayer: Object) => {
    gtm.dataLayer({
        dataLayer: dataLayer
    })
}

type FormType = 'lead_form' | 'fast_lead_form'
type SubmitResult = 'success' | 'failure'

const triggerSubmitFormEvent = (formType: FormType, submitResult: SubmitResult, insuranceType?: InsuranceType) => {
    trigger({
        event:'submit_form',
        form_type: formType,
        submit_result: submitResult,
        insurance_type: insuranceType || 'general'
    })
}

const triggerContactInfoFormEvent = (formType: FormType, insuranceType?: InsuranceType) => {
    trigger({
        event:'contact_info_form',
        form_type: formType,
        insurance_type: insuranceType || 'general'
    })
}

const GTM = {
    initialize, 
    trigger, 
    triggerSubmitFormEvent,
    triggerContactInfoFormEvent
}

export default GTM