import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import "./Reviews.css"
import { IReview } from "../../api"
import Review from "./Review"
import { connect } from 'react-redux'
import { fetchReviews } from '../../actions'
import { makeStyles, createStyles, Theme } from "@material-ui/core"
import { useState, useEffect } from "react"

const useStyles = makeStyles((theme: Theme) => createStyles({
    title:{
        color:'#3C748F',
        fontSize: theme.typography.h1.fontSize,
        fontWeight: theme.typography.h1.fontWeight,
    },
}))

export interface Props {
    className?:string,
    reviews: IReview[],
    fetchReviews: () => void
}

export interface State {
    isSmallerThan760: boolean,
    isSmallerThan1000:boolean,
}

const Reviews = (props: Props) =>{
    const [isSmallerThan760, setIsSmallerThan760] = useState(false)
    const [isSmallerThan1000, setIsSmallerThan1000] = useState(false)

    const resize = () => {
        setIsSmallerThan760(window.innerWidth <= 760)
        setIsSmallerThan1000(window.innerWidth <= 1000)
    } 

    useEffect(() =>{
        props.fetchReviews();

        window.addEventListener("resize", resize);
        window.addEventListener("orientationchange", resize);
        
        resize();
    },[])

    const createSliderSettings = () => {
        return  {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: isSmallerThan760 ? 1 : isSmallerThan1000 ? 2: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
        }
    }

    const renderReviews = () => {
        let i:number = 0; 
        return props.reviews.map(review => <Review key={i++} review={review}/>);
    }

    const classes = useStyles();

    return (
        <div className={(props.reviews.length > 0) ? 'Reviews loaded ' + props.className : 'Reviews '  + props.className}>
                <div className='content'>
                    <h1 className={classes.title}>לקוחות ממליצים</h1>
                    <Slider {...createSliderSettings()}>
                        {renderReviews()}
                    </Slider>
                </div>
            </div>)
}

const mapStateToProps = (state:any) => {
    return ({
        reviews: state.reviews
    });
};

export default connect(mapStateToProps, { fetchReviews })(Reviews)