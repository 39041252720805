import * as React from 'react'
import { PersonalAccidentsInsuranceCard } from '../InsurancesCardsPageRow'
import Collapsible from '../../Common/Collapsible'
import { withStyles } from '@material-ui/core'
import styles, { Classes } from './styles'

export interface Props {
    classes: Classes
}

const PersonalAccidentsInsuranceFAQPageRow = (props: Props) => {
    const { classes } = props
    return (
        <div>
            <div className={classes.title}>
                <PersonalAccidentsInsuranceCard className={classes.card}/>
            </div>
            <div className={classes.questions}>
                <Collapsible style={{ marginBottom:10 }} title='מה מכסה פוליסת תאונות אישיות?'>
                    <div style={{paddingBottom:15, paddingTop:15}}>ברוב החברות כיסוי תאונות אישיות מכסה 6 כיסויים עיקריים:<br/>
                    <ul>
                        <li className={classes.listItem}>מוות מתאונה</li>
                        <li className={classes.listItem}>נכות מתאונה</li>
                        <li className={classes.listItem}>שברים מתאונה</li>
                        <li className={classes.listItem}>כוויות מתאונה</li>
                        <li className={classes.listItem}>סיעוד מתאונה</li>
                        <li className={classes.listItem}>פיצוי יומי בגין אשפוז מתאונה</li>
                    </ul>
                    </div>
                </Collapsible>
                <Collapsible style={{ marginBottom:10 }} title='האם קיימת תקופת אכשרה בפוליסת תאונות אישיות ?'>
                    <p>לא, פוליסת תאונות אישיות אינה כוללת תקופת אכשרה.</p>
                </Collapsible>
                <Collapsible style={{ marginBottom:10 }} title='מה חשוב לדעת לפני שרוכשים פוליסת תאונות אישיות?'>
                    <div style={{paddingBottom:15, paddingTop:15}}>בדומה לביטוחים האחרים, חשוב להתאים ביטוח תאונות אישיות לצרכים האישיים של הרוכש ולכן מומלץ להיעזר באיש מקצוע בעל רישיון.<br/>
                    אנו מאמינים כי אנשים המצויים ברמת סיכון יחסית גבוהה זקוקות לביטוח זה.<br/>
                    כגון:<br/>
                    <ul>
                        <li className={classes.listItem}>אדם הרוב על דו גלגלי</li>
                        <li className={classes.listItem}>אדם המתעסק בעבודת גפיים</li>
                        <li className={classes.listItem}>אדם שמתעסק בפעילות בספורט אתגרית, מוטורית וכד</li>
                    </ul>
                    </div>
                </Collapsible>
                <Collapsible style={{ marginBottom:10 }} title='כיצד אדע מהו הכיסוי המתאים לי ביותר בביטוח תאונות אישיות?'>
                    <p>משתנה ממקרה למקרה, מומלץ להתייעץ עם סוכן ביטוח המתמחה בתחום.</p>
                </Collapsible>
                <Collapsible style={{ marginBottom:10 }} title='מהי נכות תעסוקתית?'>
                    <p>כיסוי המקנה שכר חודשי במקרה של תאונה למשך שנה עד 3 שנים (תלוי בכיסוי ובחברת הביטוח).</p>
                </Collapsible>
                <Collapsible style={{ marginBottom:10 }} title='ברכישת פוליסת תאונות אישיות, האם חברת הביטוח דורשת מילוי טופס הצהרת בריאות?'>
                    <p>כן, בניגוד לביטוח בריאות וביטוח חיים, בפוליסת תאונות אישיות יש למלא טופס הצהרת בריאות מקוצר הכולל לרוב 3-5 שאלות (שאלון הבריאות שונה בין חברה לחברה).<br/>
                    במידה ואחת השאלות בטופס תענה בחיוב, חברת הביטוח רשאית להחריג כיסוי ספציפי ואף לא לבטח.<br/>
                    אנו ממליצים  להתייעץ עם סוכן ביטוח כדי שיתאיים לכם את הכיסוי הנכון בהתאם להצהרת הבריאות.
                    </p>
                </Collapsible>
                <Collapsible style={{ marginBottom:10 }} title='האם כיסוי הביטוח הוא לכל החיים?'>
                    <p>לא, תקופת הביטוח משתנה בין חברה לחברה (לרוב עד גיל 80).</p>
                </Collapsible>
                <Collapsible style={{ marginBottom:10 }} title='האם המחיר משתנה או קבועה?'>
                    <p>המחיר עבור פוליסת תאונות אישיות הוא קבוע וצמוד למדד למעט שינוי חד פעמי בגילאים 18-23 (משתנה מחברה לחברה).</p>
                </Collapsible>
            </div>
        </div>
    )
}

export default withStyles(styles)(PersonalAccidentsInsuranceFAQPageRow)
