import { Button, TextField, Theme, withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Form } from '../Common'
import { connect } from 'react-redux'
import { verifyToken, postContactForm } from '../../actions' 
import { ICampaignParams, IReduxState } from '../../reducers'
import { IContact } from '../../api'
import LoadingGif from '../../images/loading.gif'

const styles = (theme: Theme) =>({
    title:{
        color: 'white'
    },
    subtitle:{
        color: 'white'
    },
    blockingDiv:{
        psotion:'fixed',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        zIndex:10
    },
    submit:{
        fontSize:'1.2em',
        marginBottom: 20,
        padding: 0,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: 'white',
        color: '#165372',
        borderRadius: 10,
        border: '5px solid white',
        "&:hover": {
            backgroundColor: '#467E99',
            color: 'white'
          }
    }
})

interface Classes {
    title: string
    subtitle: string
    blockingDiv: string
    submit: string
}

interface Props {
    classes: Classes
    contactForm: IContact
    postingContactForm: boolean
    postContactFormResult?: boolean
    tokenVerified?: boolean
    campaignParams: ICampaignParams
    verifyToken: (token: string) => void
    postContactForm: (contact: IContact, token: string, campaign?: string) => void 
    onSubmit: (postContactFormResult: boolean) => void
}

const TokenVerificationForm = (props: Props) =>{
    const { classes, contactForm, postingContactForm, postContactFormResult, tokenVerified, 
            onSubmit, postContactForm, verifyToken } = props

    const [token, setToken] = useState('')

    const validateToken = () => (token !== '')
    
    const handleTokenChange = (e: any) => setToken(e.target.value)

    const handleFormSubmit = async () =>{
        if (validateToken()){
            verifyToken(token)
        }
    }

    useEffect(() =>{
        tokenVerified && postContactForm(contactForm, token, props.campaignParams.campaign)
    }, [tokenVerified])

    useEffect(() =>{
        if (postContactFormResult !== undefined) 
            onSubmit(postContactFormResult)
    }, [postContactFormResult])
    
    return (
        <Form onSubmit={handleFormSubmit}>
            <h1 className={classes.title}>אימות באמצעות SMS</h1>
            <h3 className={classes.subtitle}>קוד הזיהוי נשלח אליך ב SMS למספר { contactForm.phoneNumber }</h3>
            
            <TextField variant='outlined' name='token' type='number' placeholder='קוד זיהוי' inputMode='numeric' 
                inputProps={{ pattern:'[0-9]*', style:{ backgroundColor:'white', borderRadius: 4 } }}
                onChange={handleTokenChange}
                helperText={(tokenVerified === false) ? 'קוד זיהוי שגוי' : ' '} 
                error={(tokenVerified === false)}/>

            <Button id={'btn_fast_submit_lead_form'} className={classes.submit} variant='contained' color='primary' type='submit'>
                המשך
            </Button>
            <div>
            {(postingContactForm) && <div className={classes.blockingDiv}/>}
            {(postingContactForm) && <img src={LoadingGif} width="50px" height="50px" alt='Loading Gif'/>}
            </div>
        </Form>
    )
}

const TokenVerificationFormWithStyle = withStyles(styles)(TokenVerificationForm)

const mapStateToProps = (state:IReduxState) => {
    return ({
        contactForm: state.contactForm,
        tokenVerified : state.twoWayAuthentication.tokenVerified,
        postingContactForm: state.postContactFormState.posting,
        postContactFormResult: state.postContactFormState.result,
        campaignParams: state.campaignParams
    });
};

export default connect(mapStateToProps, { postContactForm, verifyToken })(TokenVerificationFormWithStyle);