import * as React from 'react'
import { HealthInsuranceCard } from '../InsurancesCardsPageRow'
import Collapsible from '../../Common/Collapsible'
import { withStyles } from '@material-ui/core'
import styles,{ Classes } from './styles'

export interface Props {
    classes: Classes
}

const HealthInsuranceFAQPageRow = (props: Props) => {
    const { classes } = props
    return (
        <div>
            <div className={classes.title}>
                <HealthInsuranceCard className={classes.card}/>
            </div>
            <div className={classes.questions}>
                <Collapsible style={{ marginBottom:10 }} title='מהו ביטוח בריאות?'>
                    <p>ביטוח בריאות הוא הסכם בין המבוטח לחברת הביטוח, המעניק למבוטח זכות להחזרים כספיים כנגד שירותים רפואיים מסוימים.<br/>
                    בפוליסה מוגדרים השירותים הרפואיים הזכאים להחזר כספי מחברת הביטוח.
                    </p>
                </Collapsible>
                <Collapsible style={{ marginBottom:10 }} title='יש לי כבר ביטוח בקופת החולים, למה צריך עוד אחד?'>
                    <p>ביטוח בריאות פרטי, מהווה כיסוי משלים עבור שירותים רפואיים שאינם כלולים בביטוח הבריאות הממלכתי.<br/>
                    לרוב מדובר בטיפולים המתאפיינים בעלות גבוהה במיוחד, כגון ניתוחים בחו'ל, השתלות, תרופות מצילות חיים ועוד.
                    </p>
                </Collapsible>
                <Collapsible style={{ marginBottom:10 }} title='מה כלול בביטוח הבריאות?'>
                    <p>הפוליסה היא משתנה, ולרוב מותאמת בצורה אישית למבוטח.<br/>
                    בין הרכיבים הנפוצים שתוכלו למצוא בפוליסות ביטוחי הבריאות: תרופות שאינן מכוסות בסל הבריאות, השתלות, ניתוחים בחו'ל, בדיקות גנטיות ועוד.
                    </p>
                </Collapsible>
                <Collapsible style={{ marginBottom:10 }} title='איך מקבלים החזר כספי דרך חברת הביטוח?'>
                    <p>יש לשמור את הקבלות מתשלום על השירותים הרפואיים, ואותן לשלוח אל חברת הביטוח.<br/>
                    במידה והשירותים הרפואיים מכוסים במסגרת הפוליסה – חברת הביטוח תעביר את הכסף למבוטח תוך מספר ימים.
                    </p>
                </Collapsible>
            </div>
        </div>)
}

export default withStyles(styles)(HealthInsuranceFAQPageRow)