import React from 'react'
import { Button, Theme, withStyles } from '@material-ui/core'
import { Form } from '../Common'
import { updateContactForm } from '../../actions' 
import { connect } from 'react-redux'
import { IContact } from '../../api'

const styles = (theme: Theme) =>({
    title:{
        color: 'white',
        fontSize: theme.typography.h1.fontSize,
        fontWeight: theme.typography.h1.fontWeight,
        [theme.breakpoints.down('xs')]:{
            fontSize:'2em',
        }
    },
    subTitle:{
        marginTop:0,
        color: 'white',
        [theme.breakpoints.down('xs')]:{
            fontSize:'1em',
        }
    },
    button:{
        fontSize: '1.2em', 
        marginTop: 2,
        padding: 0,
        marginLeft: 40,
        marginRight: 40,
        minWidth: 260,
        backgroundColor: 'white',
        color: '#165372',
        borderRadius: 10,
        border: '5px solid white',
        "&:hover": {
            backgroundColor: '#467E99',
            color: 'white'
          },
        [theme.breakpoints.down('xs')]:{
            marginLeft:0,
            marginRight:0,
        }
    }
})

interface Classes {
    title: string
    subTitle: string
    button: string
}

interface Props{
    classes: Classes
    onSubmit: () => void
    updateContactForm: (contactForm: IContact) => void
}

const InsuranceSelectionForm = (props: Props) =>{
    const { classes, updateContactForm, onSubmit } = props
    
    return(
        <Form onSubmit={onSubmit} noValidate>
            <h1 className={classes.title}>{`מעוניינים בהשוואת`}<br/>{`ביטוחים?`}</h1>
            <h2 className={classes.subTitle}>{`השוו באופן מיידי בתחומים:`}</h2>
            <Button className={classes.button} variant='contained' color='primary' type='submit'
                onClick={() => updateContactForm({ insuranceType: 'life_insurance' })}>
                ביטוח חיים
            </Button>
            <Button className={classes.button} variant='contained' color='primary' type='submit'
                onClick={() => updateContactForm({ insuranceType:'health_insurance' })}>
                ביטוח בריאות
            </Button>
            <Button className={classes.button} variant='contained' color='primary' type='submit'
                onClick={() => updateContactForm({ insuranceType:'personal_accidents_insurance' })}>
                ביטוח תאונות אישיות
            </Button>
            <Button className={classes.button} variant='contained' color='primary' type='submit'
                onClick={() => updateContactForm({ insuranceType:'loss_of_work_fitness_insurance' })}>
                ביטוח אובדן כושר עבודה
            </Button>
            <Button className={classes.button} variant='contained' color='primary' type='submit'
                onClick={() => updateContactForm({ insuranceType:'severe_illness_insurance' })}>
                ביטוח מחלות קשות
            </Button>
            <p className={classes.subTitle} style={{marginTop:20}}>{`בדיקת ביטוחים קיימים`}<br/>{`כולל ניתוח תיק מקיף ע"י המומחים שלנו.`}</p>
            <h2 className={classes.subTitle}>{`למידע נוסף:`}</h2>
            <h3 className={classes.subTitle} style={{marginTop:0}}>{`052-897-5001`}</h3>
        </Form>
    )
}

const InsuranceSelectionFormWithStyles = withStyles(styles)(InsuranceSelectionForm)
export default connect(null, { updateContactForm })(InsuranceSelectionFormWithStyles)