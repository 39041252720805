import api, {IContact, IReview} from '../api'
import { ICampaignParams } from '../reducers'

export const FETCH_REVIEWS = "FETCH_REVIEWS"
export const POST_CONTACT = "POST_CONTACT"
export const CREATE_CONTACT_FORM = "CREATE_CONTACT_FORM"
export const UPDATE_CONTACT_FORM = "UPDATE_CONTACT_FORM"
export const SEND_TOKEN = "SEND_TOKEN"
export const VERIFY_TOKEN = "VERIFY_TOKEN"
export const SET_ACCESSIBILITY = "SET_ACCESSIBILITY"
export const SET_CAMPAIGN = "SET_CAMPAIGN"


export function fetchReviews() {
    return function (dispatch: any) {
        api.getReviews()
            .then((reviews: IReview[]) => dispatch({ type:FETCH_REVIEWS, payload: reviews}))
            .catch(err=>console.log(err));
    }
}

export function postContactForm(contact: IContact, token: string, campaign?: string) {
    return function (dispatch: any) {
        dispatch({ type:POST_CONTACT, payload: { posting: true, result: undefined }});
        api.postContact(contact, token, campaign)
            .then((success: any) => {
                dispatch({ type:POST_CONTACT, payload: { posting: false, result: success }});
            })
            .catch(err=>{
                console.log(err);
                dispatch({ type:POST_CONTACT, payload: { posting: false, result: false }})});
    }
}

export function sendToken(phoneNumber: string) {
    return function (dispatch: any) {
        dispatch({ type:SEND_TOKEN, payload: { sendingToken: true, tokenSent: undefined }});
        api.sendToken(phoneNumber)
            .then((success: any) => {
                dispatch({ type:SEND_TOKEN, payload: { sendingToken: false, tokenSent: success }});})
            .catch(err=>{
                console.log(err);
                dispatch({ type:SEND_TOKEN, payload: { sendingToken: false, tokenSent: false }})});
    }
}

export function sendTokenWithContact(phoneNumber: string, contact: IContact) {
    return function (dispatch: any) {
        dispatch({ type:SEND_TOKEN, payload: { sendingToken: true, tokenSent: undefined }});
        api.sendTokenWithContact(phoneNumber, contact)
            .then((success: any) => {
                dispatch({ type:SEND_TOKEN, payload: { sendingToken: false, tokenSent: success }});})
            .catch(err=>{
                console.log(err);
                dispatch({ type:SEND_TOKEN, payload: { sendingToken: false, tokenSent: false }})});
    }
}

export function verifyToken(token: string) {
    return function (dispatch: any) {
        dispatch({ type:VERIFY_TOKEN, payload: { verifyingToken: true, tokenVerified: undefined }});
        api.verifyToken(token)
            .then((success: any) => {
                dispatch({ type:VERIFY_TOKEN, payload: { verifyingToken: false, tokenVerified: success }});})
            .catch(err=>{
                console.log(err);
                dispatch({ type:VERIFY_TOKEN, payload: { verifyingToken: false, tokenVerified: false }})});
    }
}

export function clearSendTokenState() {
    return { type:SEND_TOKEN, payload: { sendingToken: false, tokenSent: undefined }};
}

export function clearVerifyTokenState() {
    return { type:VERIFY_TOKEN, payload: { verifyingToken: false, tokenVerified: undefined }};
}

export function clearPostContactState() {
    return { type:POST_CONTACT, payload: { posting: false, result: undefined }};
}

export function createContactForm() {
    return { type: CREATE_CONTACT_FORM, payload: {} as IContact };
}

export function updateContactForm(contact: IContact){
    return { type: UPDATE_CONTACT_FORM, payload: contact};
}

export function setAccessibility(accassibility: { highContrast: boolean, fontSize: number }){
    return { type: SET_ACCESSIBILITY, payload: accassibility }
}

export function resetAccessibility(){
    return { type: SET_ACCESSIBILITY, payload: { highContrast:false, fontSize: 1 } }
}

export function setCampaignParams(campaignParams: ICampaignParams) {
    return { type: SET_CAMPAIGN, payload: campaignParams };
}

export function initContact() {
    return function (dispatch: any) {
        dispatch(createContactForm())
        dispatch(clearPostContactState())
        dispatch(clearVerifyTokenState())
    }
}