import * as React from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core"
import ManImg from "../../images/man.png"

const useStyles = makeStyles((theme: Theme) => createStyles({
    rightContainer:{
        float: 'right',
        width: 'calc(100% - 447px)',
        [theme.breakpoints.down('md')]:{
            width: 'calc(100% - 40px)'
        },
    },
    leftContainer:{
        float: 'left',
        width: 417,
        height: 388,
        [theme.breakpoints.down('md')]:{
            display: 'none'
        },
    },
    image:{
        height: '120%',
        position: 'relative',
        top: '-18%',
        left: 0
    },
    title:{
        color: 'white',
        textAlign: 'right',
        marginRight: 20,
        fontSize: theme.typography.h1.fontSize,
        fontWeight: theme.typography.h1.fontWeight
    },
    instrucions:{
      float:'right',
      textAlign: 'right',
    },
    step:{
        color: 'white',
        textAlign: 'right',
        marginBottom: 20,
        marginRight: 40,
    },
    stepPoint:{
        width:27,
        height:27,
        backgroundColor: 'white',
        color: '#3E3E3F',
        borderRadius: 25,
        textAlign: 'center',
        padding: 5,
        marginLeft: 20
    },
    triangleDown:{
        width: 0,
        height: 0,
        position:'absolute',
        right: 'calc(50% - 15px)',
        top: 0,
        borderLeft: '20px solid transparent',
        borderRight: '20px solid transparent',
        borderTop: '30px solid white',
      }
  }))

export interface Props {
    className?: string
    style?: React.CSSProperties
}

const HowItWorksPageRow = (props:Props) =>  {
    const classes = useStyles()
    return (
        <div>
            <div className={classes.triangleDown}/>
            <div className={classes.rightContainer + ' ' + props.className} style={{...props.style}}>
                <h1 className={classes.title}>איך זה עובד ?</h1>
                <div className={classes.instrucions}>
                    <div className={classes.step}>
                        <div className={classes.stepPoint}>
                            <h3 style={{margin: 0}}>1.</h3>
                        </div>
                        <h2 style={{margin: 0}}>בוחרים ביטוח</h2>
                    </div>
                    <div className={classes.step}>
                        <div className={classes.stepPoint}>
                            <h3 style={{margin: 0}}>2.</h3>
                        </div>
                        <h2 style={{margin: 0}}>עונים על שאלון קצר</h2>
                    </div>
                    <div className={classes.step}>
                        <div className={classes.stepPoint}>
                            <h3 style={{margin: 0}}>3.</h3>
                        </div>
                        <h2 style={{margin: 0, marginRight: 60}}>סוכן ביטוח מטעמנו ייצור עימך קשר עם ההצעה הטובה ביותר עבורך</h2>
                    </div>
                </div>
            </div>
            <div className={classes.leftContainer}>
                <img src={ManImg} className={classes.image} alt="Man"></img>
            </div>      
        </div>
    )
}

export default HowItWorksPageRow